import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import history from "../../../utils/routes/history";
import { analytics_LandingPageView } from "../../../utils/analytics/events";

import ExternalHeader from "../../common/headers/externalHeader/externalHeaderContainer";
import Footer from "../../common/footers/LandingFooter";

import signupAnimation from "../../../assets/lotties/signup.json";
import uploadAnimation from "../../../assets/lotties/upload.json";
import relaxAnimation from "../../../assets/lotties/relax.json";

import laptop from "../../../assets/laptop.png";
import CertficatesPageImage from "../../../assets/certificatesPage.png";
import SeatimePageImage from "../../../assets/seatimePage.png";
import TrackerPageImage from "../../../assets/trackerPage.png";

const Landing = props => {
  // Analytics
  useEffect(() => {
    analytics_LandingPageView();
  }, []);

  return (
    <div className="bg-white overflow-hidden font-serif">
      <div
        className="hidden lg:block lg:absolute lg:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
          width="640"
          height="784"
          fill="none"
          viewBox="0 0 640 784"
        >
          <defs>
            <pattern
              id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
              x="118"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            y="72"
            width="640"
            height="640"
            className="text-gray-50"
            fill="currentColor"
          />
          <rect
            x="118"
            width="404"
            height="784"
            fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
          />
        </svg>
      </div>
      <div className="pt-6">
        <ExternalHeader />
      </div>

      <Helmet>
        <title>Ascertive | Seafarer Certificate Management</title>
        <meta
          name="title"
          content="Ascertive | Seafarer Certificate Management"
        />
        <meta
          name="description"
          content="Retire that spreadsheet and take charge of your career. Ascertive is a seamless certificate management solution built for seafarers, by seafarers. Certificate expiry tracking. Digital seatime calculator. Secure document sharing via the Ascertive Tracker."
        />
      </Helmet>

      <main>
        <section
          id="hero"
          className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32 pt-6 pb-16 sm:pb-24 lg:pb-32"
        >
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left z-50">
              <h1>
                <span className="mt-1 font-sans block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className="block text-gray-900">Stay ahead</span>
                  <span className="block text-secondary">
                    by never falling behind
                  </span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Ascertive is a seamless certificate management solution built
                for seafarers, by seafarers.
              </p>
              <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                <p className="text-base font-medium text-gray-900">
                  Get started now!
                </p>
                <form
                  onClick={() => history.push("signup")}
                  className="mt-3 sm:flex z-50"
                >
                  <label for="email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="pl-4 block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1  border border-gray-300"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-success shadow-sm   sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                  >
                    Sign Up
                  </button>
                </form>
              </div>
            </div>
            <div className="mt-24 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full">
                <img
                  className="w-full"
                  src={laptop}
                  alt="Dashboard viewed on Laptop"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="take control" className="relative">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h3 className="text-center text-2xl font-semibold uppercase text-gray-600 tracking-wider">
              Take control of your career
            </h3>
          </div>
        </section>
        {/* // ========================== // PANELS // ==========================
         */}
        <section
          className="bg-white pt-16 md:pb-48 pb-24 overflow-hidden"
          id="features"
        >
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-secondary">
                      {/* Dashboard */}
                      <svg
                        viewBox="-170 -170 900 900"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-12 w-12"
                        fill="white"
                        stroke="white"
                        aria-hidden="true"
                      >
                        <path d="m251.25 12.5c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 213.75h-201.25v-201.25h201.25zm0 0" />
                        <path d="m562.5 12.5c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 213.75h-201.25v-201.25h201.25zm0 0" />
                        <path d="m251.25 323.75c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 212.5h-201.25v-200h201.25zm0 0" />
                        <path d="m562.5 323.75c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 212.5h-201.25v-200h201.25zm0 0" />
                      </svg>
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Stay on top of your expiries
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Employers want you to be ready any time, any place. Upload
                      your certificates to Ascertive to take advantage of our
                      automatic expiry tracking. Ascertive lets you know how
                      long you have until your next renewal, and recommends a
                      plan so that you're never caught out.
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/signup"
                        className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-success hover:bg-success"
                      >
                        Get started
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        Sync data with your favorite calendar app. Receive
                        current summaries and critical dates through
                        notifications sent by email.
                      </p>
                    </div>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={CertficatesPageImage}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-secondary">
                      {/* <!-- Heroicon name: outline/sparkles --> */}
                      <svg
                        viewBox="-18 -18 100 100"
                        className="h-12 w-12"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        stroke="white"
                        aria-hidden="true"
                      >
                        <g id="Calculator">
                          <path d="M49,62H15a7.008,7.008,0,0,1-7-7V9a7.008,7.008,0,0,1,7-7H49a7.008,7.008,0,0,1,7,7V55A7.008,7.008,0,0,1,49,62ZM15,4a5.006,5.006,0,0,0-5,5V55a5.006,5.006,0,0,0,5,5H49a5.006,5.006,0,0,0,5-5V9a5.006,5.006,0,0,0-5-5Z" />
                          <path d="M35,58H29a3,3,0,0,1-3-3V51a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v4A3,3,0,0,1,35,58Zm-6-8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V51a1,1,0,0,0-1-1Z" />
                          <path d="M21,58H15a3,3,0,0,1-3-3V51a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v4A3,3,0,0,1,21,58Zm-6-8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V51a1,1,0,0,0-1-1Z" />
                          <path d="M49,58H43a3,3,0,0,1-3-3V39a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3V55A3,3,0,0,1,49,58ZM43,38a1,1,0,0,0-1,1V55a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V39a1,1,0,0,0-1-1Z" />
                          <path d="M35,46H29a3,3,0,0,1-3-3V39a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v4A3,3,0,0,1,35,46Zm-6-8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V39a1,1,0,0,0-1-1Z" />
                          <path d="M21,46H15a3,3,0,0,1-3-3V39a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v4A3,3,0,0,1,21,46Zm-6-8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V39a1,1,0,0,0-1-1Z" />
                          <path d="M35,34H29a3,3,0,0,1-3-3V27a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v4A3,3,0,0,1,35,34Zm-6-8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V27a1,1,0,0,0-1-1Z" />
                          <path d="M21,34H15a3,3,0,0,1-3-3V27a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v4A3,3,0,0,1,21,34Zm-6-8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V27a1,1,0,0,0-1-1Z" />
                          <path d="M49,34H43a3,3,0,0,1-3-3V27a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v4A3,3,0,0,1,49,34Zm-6-8a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V27a1,1,0,0,0-1-1Z" />
                          <path d="M49,20H15a3,3,0,0,1-3-3V9a3,3,0,0,1,3-3H49a3,3,0,0,1,3,3v8A3,3,0,0,1,49,20ZM15,8a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H49a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1Z" />
                          <path d="M46,17a3,3,0,0,1-3-3V12a3,3,0,0,1,6,0v2A3,3,0,0,1,46,17Zm0-6a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V12A1,1,0,0,0,46,11Z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Keep tabs on your seatime
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Everyone hates keeping their logs and calculating their
                      seatime. Use Ascertive's online discharge book to track
                      your time onboard. Simply enter your logs and let
                      Ascertive calculate and update your postion
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/signup"
                        className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-success"
                      >
                        Get started
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        Discover and plan your future with our calculated
                        summaries and sea-time tracking. Cross-examine your
                        seatime by rank and ship type.
                      </p>
                    </div>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={SeatimePageImage}
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="relative mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-secondary">
                      {/* <!-- Heroicon name: outline/inbox --> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-140 -140 800 800"
                        className="h-12 w-12"
                        fill="white"
                        stroke="white"
                        aria-hidden="true"
                      >
                        <g>
                          <g>
                            <path
                              d="M497,240.999c-8.284,0-15,6.716-15,15c0,124.618-101.382,226.002-225.998,226.002
			C131.387,481.999,30.003,380.616,30,256.001c0.003-124.617,101.385-226,225.998-226C311.337,30,363.632,49.767,404.844,85.941
			l-47.091,47.092c-28.561-23.718-64.163-36.632-101.751-36.632c-88.004,0-159.6,71.596-159.6,159.6s71.596,159.6,159.6,159.6
			c88.003,0,159.599-71.596,159.599-159.6c0-8.284-6.716-15-15-15s-15,6.716-15,15c0,71.461-58.138,129.6-129.599,129.6
			c-71.462,0-129.6-58.138-129.6-129.6s58.138-129.6,129.6-129.6c29.564,0,57.615,9.841,80.423,27.961l-47.608,47.609
			c-9.792-5.971-21.058-9.17-32.821-9.169c-34.847,0-63.197,28.351-63.197,63.198c0,34.848,28.352,63.199,63.2,63.199
			c34.848,0,63.2-28.35,63.2-63.197c0-11.761-3.199-23.026-9.17-32.818l126.988-126.99c2.929-2.93,4.393-6.769,4.393-10.608
			c0-3.84-1.464-7.68-4.393-10.609C388.665,26.626,324.378,0,255.998,0.001c-68.378,0-132.665,26.629-181.016,74.98
			C26.63,123.332,0.001,187.621,0,256.001c0.001,68.379,26.631,132.665,74.982,181.017c48.352,48.352,112.639,74.981,181.02,74.982
			c68.379,0,132.665-26.629,181.017-74.981C485.371,388.665,512,324.379,512,255.999C512,247.715,505.284,240.999,497,240.999z
			 M256,289.199c-18.307,0-33.2-14.893-33.2-33.199c0-18.306,14.892-33.198,33.201-33.198c8.866-0.001,17.202,3.451,23.472,9.72
			c0.001,0.001,0.003,0.002,0.004,0.003c0.001,0.001,0.002,0.002,0.003,0.003c6.268,6.27,9.721,14.606,9.721,23.474
			C289.2,274.308,274.307,289.199,256,289.199z"
                            />
                          </g>
                        </g>
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                        <g />
                      </svg>
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Stop emailing your sensitive documents.
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Share your private information securely across the world
                      with Ascertive's Tracker. Maintain control of your
                      documents while gaining access to employment and training
                      opportunities across the world.
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/signup"
                        className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-success hover:bg-success"
                      >
                        Get started
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        Learn what employers are looking at your information,
                        including information such as where in the world and
                        when they are accessing it from.
                      </p>
                    </div>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={TrackerPageImage}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 text-gray-600 body-font mx-auto">
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-20">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Get Started with Ascertive in three easy steps
              </h2>
              <div className="flex mt-6 justify-center">
                <div className="w-16 h-1 rounded-full bg-primary inline-flex" />
              </div>
            </div>
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div className="pt-6">
                  <div className="h-full flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div className="flex justify-center">
                        <span className="inline-flex items-center justify-center p-4 bg-secondary rounded-md shadow-lg">
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: signupAnimation,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                              }
                            }}
                            height={36}
                            width={36}
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-2xl text-center font-medium text-gray-900 tracking-tight">
                        Sign up
                      </h3>
                      <p className="mt-5 text-base text-lg text-center text-gray-500">
                        Create an Ascertive account for free. Sign up via email,
                        Facebook or Google.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="h-full flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div className="flex justify-center">
                        <span className="inline-flex items-center justify-center p-1.5 bg-secondary rounded-md shadow-lg">
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: uploadAnimation,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                              }
                            }}
                            height={55}
                            width={55}
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-2xl text-center font-medium text-gray-900 tracking-tight">
                        Upload
                      </h3>
                      <p className="mt-5 text-base text-lg text-center text-gray-500">
                        Upload your file safely to our secure cloud servers.
                        Input your data in a matter of minutes using Ascertives
                        helpful tools.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <div className="h-full flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div className="flex justify-center">
                        <span className="inline-flex items-center justify-center p-1.3 bg-secondary rounded-md shadow-lg">
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: relaxAnimation,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                              }
                            }}
                            height={68}
                            width={68}
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-2xl text-center font-medium text-gray-900 tracking-tight">
                        Relax
                      </h3>
                      <p className="mt-5 text-base text-lg text-center text-gray-500">
                        Sit back and relax! Ascertive will manage your expiries
                        and let you know in advance when they fall due.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="relative my-24 sm:my-8 sm:py-16">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="relative bg-secondarylight rounded-2xl px-6 py-10 overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div
                aria-hidden="true"
                className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
              />
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                    Retire that spreadsheet. Invest in your future now.
                  </h2>
                </div>
                <div className="flex justify-center">
                  <Link
                    to="/signup"
                    className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-success hover:bg-success sm:w-auto font-bold"
                  >
                    Sign up now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Landing;
