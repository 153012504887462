import types from "./types";

//=======================================
//             USER ACTIONS
//=======================================

export const saveUserDatabaseInstance = instance => {
  return {
    type: types.SAVE_USER_DATABASE_INSTANCE,
    payload: instance
  };
};

//=======================================
//             AUTH ACTIONS
//=======================================

export const socialRedirect = bool => {
  return {
    type: types.SOCIAL_REDIRECT,
    payload: bool
  };
};

export const authAttempt = obj => {
  return {
    type: types.AUTH_ATTEMPT,
    payload: obj
  };
};

export const authError = error => {
  return {
    type: types.AUTH_ERROR,
    payload: error
  };
};

export const authSuccess = () => {
  return {
    type: types.AUTH_SUCCESS
  };
};

export const authLogout = () => {
  return {
    type: types.AUTH_LOGOUT
  };
};

export const saveUserAuthObject = obj => {
  return {
    type: types.SAVE_USER_AUTH_OBJECT,
    payload: obj
  };
};

//=======================================
//        ENTRANCE UI ACTIONS
//=======================================

export const entranceLoading = bool => {
  return {
    type: types.ENTRANCE_LOADING,
    payload: bool
  };
};

export const gatherDetails = bool => {
  return {
    type: types.GATHER_DETAILS,
    payload: bool
  };
};

export const gatherDetailsComplete = obj => {
  return {
    type: types.GATHER_DETAILS_COMPLETE,
    payload: obj
  };
};

export const betaScreen = bool => {
  return {
    type: types.BETA_SCREEN,
    payload: bool
  };
};
