import React, { Fragment } from "react";
import moment from "moment";

//MUI Pickers
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export default props => {
  const {
    field,
    label,
    form,
    applyMinDate,
    disabled,
    calculateExpiryDate,
    values,
    disableFuture,
    setExpiryMessage,
    ...other
  } = props;

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          fullWidth
          //Input name
          name={field.name}
          label={label}
          value={field.value}
          //Disable the input
          disabled={disabled}
          //Allows date to be selected without hitting 'OK'
          autoOk
          //Outline the input
          inputVariant="outlined"
          // Opens to year, month and then day
          openTo="year"
          views={["year", "month", "date"]}
          // disables input of a date in the future
          disableFuture={disableFuture === false ? false : true}
          //will not allow user to enter date before signon date
          minDate={applyMinDate && applyMinDate}
          minDateMessage="Sign Off date should come after Sign On date"
          //Format user must enter date via keyboard (mask)
          format="DD/MM/YYYY"
          placeholder="DD/MM/YYYY"
          //handler for updating form values
          onChange={date => {
            form.setFieldValue(
              field.name,
              date && date.format("YYYY-MM-DD"),
              true
            );
            // sets the expiry date field in the cert form
            // calculate expiry date is a bool passed in from
            // the parent form.
            if (calculateExpiryDate) {
              const validity = form.values.validity;

              if (validity !== "") {
                let expiryDate = moment(date)
                  .add(validity, "y")
                  .format("YYYY-MM-DD");
                form.setFieldValue("expiryDate", expiryDate, true);
                setExpiryMessage(true);
              }
            }
          }}
          {...other}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};
