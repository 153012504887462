import types from "./types";
import { entranceTypes } from "../../../pages/entrance/duck";

const initialState = {
  //true means snackbar in view
  open: false,
  //snackbar content - contains message to display & type i.e. "error" or "info"
  data: { message: null, type: null }
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SNACKBAR_OPEN:
      return {
        ...state,
        open: !state.open,
        data: action.payload
      };
    case types.SNACKBAR_CLOSE:
      return {
        ...state,
        open: false
      };
    case entranceTypes.AUTH_ERROR:
      return {
        ...state,
        open: !state.open,
        data: error(action.payload)
      };
    default:
      return state;
  }
};

export default snackbarReducer;

//ERROR - On signout the AuthError() used to reset the Saga will throw an error
//because there is error NO object being passed to 'AuthError(error)' and therefor
//no message in the payload.

//We're going to make a switch function that deals with all the auth errors thrown by
//firebase and returns a response accordingly

function error(payload) {
  switch (payload.code) {
    case "unavailable":
      return {
        type: "error",
        message:
          "Looks like you might be offline, please check your connection and try again"
      };
    case "recaptcha":
      return {
        type: "error",
        message:
          "An error has occured during ReCaptcha or it was failed. Please try again."
      };
    case "auth/login-required":
      return {
        type: "error",
        message: "Whoops, this action requires re-authentication"
      };
    case "auth/network-request-failed":
      return {
        type: "error",
        message:
          "Looks like your offline, please check your connection and try again."
      };
    case "auth/wrong-password":
      return {
        type: "error",
        message:
          "You either have the wrong password or this email is linked to a social account. Please try again."
      };
    case "auth/user-not-found":
      return {
        type: "error",
        message: "There is no account registered with this email address"
      };
    case "auth/invalid-email":
      return {
        type: "error",
        message: "Invalid email address, please enter a valid email address."
      };
    case "auth/email-already-in-use":
      return {
        type: "error",
        message:
          "There is an already an account registered with this email address"
      };
    case "auth/account-exists-with-different-credential":
      return {
        type: "error",
        message:
          "This email address is associated with another provider, please sign in with the same method used to sign up."
      };
    case "reset":
      return {
        type: "error",
        message:
          "Opps! Something went wrong, humans have been alerted! Please try again."
      };
    default:
      return {
        type: "error",
        message:
          "Opps! Something went wrong, humans have been alerted! Please try again."
      };
  }
}
