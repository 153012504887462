import { analytics } from "../../index"

// ===================================================
// PAGE VIEWS
// ===================================================

// Tracks the number of landing page views
export const analytics_LandingPageView = () => {
    analytics.logEvent("screen_view", { screen_name: "Landing" })
}

// Tracks the number of entrance views (click throughs)
export const analytics_SignUpView = () => {
    analytics.logEvent("screen_view", { screen_name: "Entrance" })
}

// ===================================================
// CONTACT US / FEEDBACK
// ===================================================

// Tracks the number of contact/feedback forms recieved
export const analytics_Contact = () => {
    analytics.logEvent("Contact/Feedback recieved")
}

// ===================================================
// CERTIFICATES AND SEATIME
// ===================================================

// Tracks certificate uploads
export const analytics_CertificateUpload = () => {
    analytics.logEvent("Certificates Uploaded")
}

// Trackes certificate adds, irrespective of whethere a file is uploaded
// excludes edits.
export const analytics_CertificateCreated = (name) => {
    analytics.logEvent("Certificates Created", { name })
}

// Tracks certificate deletions (file is also removed if the 
// certificate is deleted?)
export const analytics_CertificatesDeleted = (number) => {
    analytics.logEvent("Certificates Deleted", { number })
}

// ===================================================
// TRACKER AND PROFILE
// ===================================================

// Tracks SUCCESSFUL profile views, logs uid to identity average 
// views per profile. uid relates to the certificate owners profile
export const analytics_ProfileView = (uid) => {
    analytics.logEvent("screen_view", { screen_name: "Profile", uid })
}

// Tracks SUCCESSFUL profile views, logs uid to identity average 
// views per profile. uid relates to the certificate owners profile
export const analytics_ProfileInvalid = () => {
    analytics.logEvent("screen_view", { screen_name: "Profile Invalid" })
}

// Tracks profile certificates downloads. uid relates to the 
// certificate owners profile
export const analytics_ProfileDownload = (uid) => {
    analytics.logEvent("Profile Downloads", { uid })
}

// ===================================================
// AUTH
// =================================================== 

// Tracks signups and signins and the provider used. Note that
// the method "password" is just email auth.
export const analytics_Auth = (authObject) => {
    const { providerId, isNewUser } = authObject.additionalUserInfo
    const { uid, lastLoginAt } = authObject.user
    isNewUser ? analytics.logEvent("sign_up", { method: providerId, uid }) : analytics.logEvent("login", { method: providerId, uid, lastLoginAt })
}
