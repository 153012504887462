import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  pageContainer: {
    minHeight: "calc(100vh - 263px)",
    padding: "2rem 5rem",
    "@media (max-width: 600px)": {
      padding: "1rem 2rem"
    },
    "@media (max-width: 375px)": {
      padding: "1rem"
    }
  },
  textContainer: {
    padding: "1rem 0",
    "& p": {
      padding: "0.5rem 0"
    },
    "& ul": {
      listStyle: "disc",
      paddingLeft: "40px"
    }
  }
});
