import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { functions } from "../../../index";

import ExternalHeader from "../../common/headers/externalHeader/externalHeaderContainer";
import Footer from "../../common/footers/LandingFooter";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import styles from "../nomatch/NomatchComponent.style";

export default props => {
  const classes = styles();
  const unSub = functions.httpsCallable("email-unsubscribe");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  useEffect(() => {
    let uid = query.get("uid");
    unSub({ uid }).catch(err => console.log(err));
  }, [query, unSub]);

  return (
    <div className={classes.wrapper}>
      <ExternalHeader location={props.location} />
      <Container className={classes.contentContainer}>
        <Typography
          variant="h1"
          component="h1"
          className={`${classes.huge} ${classes.white}`}
        >
          CRY
        </Typography>
        <div>
          <Typography
            variant="h6"
            className={`${classes.text} ${classes.white}`}
            gutterBottom
          >
            Congratulations, you've been unsubscribed from all certificate and
            tracker notifications!
          </Typography>
          <Typography
            variant="h6"
            className={`${classes.text} ${classes.white}`}
          >
            We get it - inboxes get out of control, and sometimes you just need
            a break.
          </Typography>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
