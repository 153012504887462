import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { captureException } from "@sentry/react";
import { snackbarOperations } from "../../common/snackbar/duck";
import { functions } from "../../../index";
import { analytics_Contact } from "../../../utils/analytics/events";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./ContactUsComponent.style";
import ExternalHeader from "../../common/headers/externalHeader/externalHeaderContainer";
import Footer from "../../common/footers/LandingFooter";
import renderCustomInput from "../../common/forms/CustomInput";
import renderErrorMessage from "../../common/forms/messages/ErrorMessage";

import Person from "@material-ui/icons/PersonOutlined";
import Email from "@material-ui/icons/EmailOutlined";

//THERE IS ZERO ERROR HANDLING HERE

export default props => {
  const recaptcha = useRef(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = styles();

  const contactUs = functions.httpsCallable("email-contactus");
  const verifyRecaptcha = functions.httpsCallable("general-recaptcha");

  const contactSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string()
      .required("Required")
      .email("Must be a valid email address"),
    message: Yup.string()
      .min(10, "Please explain in more detail")
      .required("Required")
  });

  const renderForm = () => {
    return (
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        enableReinitialize={false}
        validationSchema={contactSchema}
        onSubmit={(values, actions) => {
          contactUs(values)
            .then(() => {
              setSuccess(true);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm();
              analytics_Contact();
              snackbarOperations.openSnackbar({
                type: "success",
                message: `Thanks for getting in touch, we'll get back to you as soon as possible!`
              });
            })
            .catch(error => {
              // send the exception off to sentry for further examination.
              captureException(error, scope => {
                scope.setTag("specifics", "Contact us form error");
              });
              // give user feedback
              snackbarOperations.openSnackbar({
                type: "error",
                message: `Something went wrong, please try again`
              });
            });
        }}
      >
        {({ handleSubmit, isSubmitting, validateForm, isValid }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <ReCAPTCHA
              ref={recaptcha}
              onErrored={_err => {
                setLoading(false);
              }}
              onChange={value => {
                let data = { token: value };
                verifyRecaptcha(data)
                  .then(res => {
                    if (res.data.success) {
                      handleSubmit();
                      recaptcha.current.reset();
                    } else {
                      setLoading(false);
                    }
                  })
                  .catch(_err => {
                    setLoading(false);
                  });
              }}
              style={{ zIndex: "999" }}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
            <div>
              <Field
                component={renderCustomInput}
                placeholder="Name"
                name="name"
                style={{ backgroundColor: "white" }}
                icon={<Person />}
              />
              <div className={classes.errorMessageContainer}>
                <ErrorMessage name="name" component={renderErrorMessage} />
              </div>
            </div>
            <div>
              <Field
                component={renderCustomInput}
                placeholder="Email"
                name="email"
                style={{ backgroundColor: "white" }}
                icon={<Email />}
              />
              <div className={classes.errorMessageContainer}>
                <ErrorMessage name="email" component={renderErrorMessage} />
              </div>
            </div>
            <div>
              <Field
                component={renderCustomInput}
                placeholder="Message"
                name="message"
                multiline={true}
                style={{ backgroundColor: "white" }}
                rows="10"
              />
              <div className={classes.errorMessageContainer}>
                <ErrorMessage name="message" component={renderErrorMessage} />
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                variant="contained"
                disabled={success || loading}
                color="primary"
                className={success ? classes.buttonSuccess : classes.button}
                onClick={() =>
                  validateForm().then(() => {
                    if (isValid) {
                      recaptcha.current.execute();
                      setLoading(true);
                    }
                  })
                }
              >
                {success ? "Sent Successfully!" : "Send"}
                {isSubmitting ||
                  (loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ))}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div className="bg-white overflow-hidden font-serif">
      <div
        className="hidden lg:block lg:absolute lg:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
          width="640"
          height="784"
          fill="none"
          viewBox="0 0 640 784"
        >
          <defs>
            <pattern
              id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
              x="118"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            y="72"
            width="640"
            height="640"
            className="text-gray-50"
            fill="currentColor"
          />
          <rect
            x="118"
            width="404"
            height="784"
            fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
          />
        </svg>
      </div>
      <div className="pt-6">
        <ExternalHeader location={props.location} />
      </div>
      <Helmet>
        <title>Ascertive | Contact Us</title>
        <meta name="title" content="Ascertive | Contact Us" />
        <meta
          name="description"
          content="Get in touch! Let us know how we can serve you better. Any improvements, criticisms and enquires are welcome."
        />
        <meta property="og:url" content="https://ascertive.com/contactus" />
        <meta
          property="twitter:url"
          content="https://ascertive.com/contactus"
        />
      </Helmet>
      <main>
        {/* <!-- Header --> */}
        <div className="bg-warm-gray-50">
          <div className="pt-8 lg:pt-16">
            <div className="max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
                Get in touch
              </h1>
              <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                Let us know how we can serve you better. Any improvements,
                criticisms and enquiries are welcome.
              </p>
            </div>
          </div>
        </div>
        <section className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <h2 className="sr-only">Contact us</h2>

            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* <!-- Contact information --> */}
              <div className="relative overflow-hidden py-10 px-6 bg-primary sm:px-10 xl:p-12">
                <div
                  className="absolute inset-0 pointer-events-none sm:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width="343"
                    height="388"
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fill-opacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff" />
                        <stop offset="1" stop-color="#fff" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width="359"
                    height="339"
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fill-opacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff" />
                        <stop offset="1" stop-color="#fff" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width="160"
                    height="678"
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fill-opacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff" />
                        <stop offset="1" stop-color="#fff" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-white">
                  Drop us a line.
                </h3>
                <p className="mt-6 text-base text-indigo-50 max-w-3xl">
                  Reach out by email, or use the form and we'll be in touch as
                  soon as possible!
                </p>
                <dl className="mt-8 space-y-6">
                  {/* <dt>
                    <span className="sr-only">Phone number</span>
                  </dt>
                  <dd className="flex text-base text-indigo-50"> */}
                  {/* <!-- Heroicon name: outline/phone --> */}
                  {/* <svg
                      className="flex-shrink-0 w-6 h-6 text-indigo-200"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="ml-3">+1 (555) 123-4567</span>
                  </dd> */}
                  <dt>
                    <span className="sr-only">Email</span>
                  </dt>
                  <dd className="flex text-base text-indigo-50">
                    {/* <!-- Heroicon name: outline/mail --> */}
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-indigo-200"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <a className="ml-3" href="mailto:support@ascertive.com">
                      support@ascertive.com
                    </a>
                  </dd>
                </dl>
                {/* <ul className="mt-8 flex space-x-12" role="list">
                  <li>
                    <a className="text-indigo-200 hover:text-indigo-100" href="#">
                      <span className="sr-only">Facebook</span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        aria-hidden="true"
                      >
                        <path
                          d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a className="text-indigo-200 hover:text-indigo-100" href="#">
                      <span className="sr-only">GitHub</span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        aria-hidden="true"
                      >
                        <path
                          d="M11.999 0C5.373 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.386.6.11.819-.26.819-.578 0-.284-.01-1.04-.017-2.04-3.337.724-4.042-1.61-4.042-1.61-.545-1.386-1.332-1.755-1.332-1.755-1.09-.744.082-.73.082-.73 1.205.086 1.838 1.238 1.838 1.238 1.07 1.833 2.81 1.304 3.493.996.109-.775.419-1.303.762-1.603C7.145 17 4.343 15.97 4.343 11.373c0-1.31.468-2.382 1.236-3.22-.124-.304-.536-1.524.118-3.176 0 0 1.007-.323 3.3 1.23.956-.266 1.983-.4 3.003-.404 1.02.005 2.046.138 3.005.404 2.29-1.553 3.296-1.23 3.296-1.23.655 1.652.243 2.872.12 3.176.77.838 1.233 1.91 1.233 3.22 0 4.61-2.806 5.624-5.478 5.921.43.37.814 1.103.814 2.223 0 1.603-.015 2.898-.015 3.291 0 .321.217.695.825.578C20.565 21.796 24 17.3 24 12c0-6.627-5.373-12-12.001-12"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a className="text-indigo-200 hover:text-indigo-100" href="#">
                      <span className="sr-only">Twitter</span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        aria-hidden="true"
                      >
                        <path
                          d="M7.548 22.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 5.305a9.828 9.828 0 01-2.828.776 4.94 4.94 0 002.165-2.724 9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 9.855v.063a4.926 4.926 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 20.289a13.941 13.941 0 007.548 2.209"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                </ul> */}
              </div>

              {/* <!-- Contact form --> */}
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <h3 className="text-lg font-medium text-gray-900 mb-6">
                  Send us a message
                </h3>
                {renderForm()}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};
