import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  loadingContainer: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  logo: {
    height: "20rem",
    marginTop: "-7.5rem"
  }
});
