//=============================================
//             SNACKBAR TYPES
//=============================================
const SNACKBAR_OPEN = "snackbar_open";
const SNACKBAR_CLOSE = "snackbar_close";

export default {
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE
};
