import React from "react";

// React Router Setup
import { Router, Route, Switch } from "react-router-dom";
import history from "../utils/routes/history";

//Routes
import indexRoutes from "../utils/routes/index";

export default () => (
  <Router history={history}>
    <Switch>
      {indexRoutes.map((prop, key) => {
        return (
          <Route
            exact={prop.exact}
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      })}
    </Switch>
  </Router>
);
