import types from "./types";

const initialState = {
  //ENTRIES
  // addEntry modal open/close
  addEntry: false,
  // editEntry modal open/close + pipe data
  editEntry: false,
  // Data to initialize edit entry form
  editEntryData: null,
  // addRotation modal open/close
  addRotation: false,

  //SHIP
  // addEntry modal open/close
  addShip: false,
  // editEntry modal open/close + pipe data
  editShip: false,
  // Data to initialize edit entry form
  editShipData: null,

  //COMMON
  // false means ship component view
  display: false,
  // deleteDialog open/close
  deleteDialog: false,
  // Data to initialize deleteDialog
  deleteData: {},
};

const seatimeReducer = (state = initialState, action) => {
  switch (action.type) {
    //ENTRY
    case types.SEATIME_ADD_ENTRY:
      return { ...state, addEntry: !state.addEntry };
    case types.SEATIME_EDIT_ENTRY:
      return {
        ...state,
        editEntry: !state.editEntry,
        editEntryData: action.payload,
      };
    case types.SEATIME_ADD_ROTATION:
      return {
        ...state,
        addRotation: !state.addRotation,
      };
    //SHIP
    case types.SEATIME_ADD_SHIP:
      return { ...state, addShip: !state.addShip };
    case types.SEATIME_EDIT_SHIP:
      return {
        ...state,
        editShip: !state.editShip,
        editShipData: action.payload,
      };
    //COMMON
    case types.SEATIME_DISPLAY:
      return { ...state, display: action.payload };
    case types.SEATIME_DELETE_DIALOG:
      return {
        ...state,
        deleteDialog: !state.deleteDialog,
        deleteData: action.payload,
      };
    //DEFAULT
    default:
      return state;
  }
};

export default seatimeReducer;
