import {
  toggleDisplay,
  addCertificate,
  editCertificate,
  deleteDialog,
  calendarDialog
} from "./actions";

export default {
  toggleDisplay,
  addCertificate,
  editCertificate,
  deleteDialog,
  calendarDialog
};
