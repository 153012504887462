import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  container: {
    position: "relative",
    height: "10rem",
    padding: "3rem"
  },
  square: {
    width: "8px",
    height: "30px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    display: "block",
    animation: "$turn 2.5s ease infinite"
  },
  top: {
    position: "absolute",
    "-webkit-transform": "rotate(90deg)",
    transform: "rotate(90deg)"
  },
  bottom: {
    position: "absolute",
    "-webkit-transform": "rotate(-90deg)",
    transform: "rotate(-90deg)"
  },
  left: {
    position: "absolute"
  },
  right: {
    position: "absolute",
    "-webkit-transform": "rotate(180deg)",
    transform: "rotate(180deg)"
  },
  //eslint-disable-next-line
  "-webkit-keyframes turn": {
    "0%": {
      transform: "translateX(0) translateY(0) rotate(0)"
    },
    "70%": {
      transform: "translateX(400%) translateY(100%) rotate(90deg)"
    },
    "100%": {
      transform: "translateX(0) translateY(0) rotate(0)"
    }
  },
  "@keyframes turn": {
    "0%": {
      transform: "translateX(0) translateY(0) rotate(0)"
    },
    "70%": {
      transform: "translateX(400%) translateY(100%) rotate(90deg)"
    },
    "100%": {
      transform: "translateX(0) translateY(0) rotate(0)"
    }
  }
}));
