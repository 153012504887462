import React from "react";

//MUI Core
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

//Icons
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

export default props => {
  const { classes, snackbar } = props;
  const { open, data } = snackbar;
  const { message, type } = data;

  const Icon = variantIcon[type];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={5000}
      onClose={props.closeSnackbar}
    >
      <SnackbarContent
        aria-describedby="snackbar"
        message={
          <span id="snackbar" className={classes.message}>
            <Icon className={classes.icon} />
            {message}
          </span>
        }
        className={classes[type]}
      />
    </Snackbar>
  );
};
