import React, { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactSVG } from "react-svg";
import { functions } from "../../../index";
import styles from "./EntranceComponent.style";

// Components
import GatherDetails from "./GatherDetails/gatherDetails";
import SignIn from "./SignIn/signIn";
import SignUp from "./SignUp/signUp";
import SignOut from "./SignOut/signOut";
import ResetPassword from "./ResetPassword/reset";
import Snackbar from "../../common/snackbar/SnackbarContainer";

import whiteLogo from "../../../assets/logo/whiteLogo.svg";
import blueLogo from "../../../assets/logo/blueLogo.svg";

/* ReCAPTCHA Flow
1) Invisible ReCaptcha element loads with entrance component
2) When 'Dive Back In' or 'Get Started' buttons are clicked, an onClick handler does two things (a) saves the correct handleSubmit func in state and (b) executes the reCaptcha via a ref passed down in props
3) Token is sent to Cloud Functions and verified - response returned
4) The ReCaptcha element will then trigger the Formik onSubmit via the handleSubmit func saved in the Entrance Component State
5) the authAttempt saga will run, triggered by the appropriate form

Notes: The handleSubmit func from Formik is passed as an object setSubmitFunc({ submit: handleSubmit }), because if it is passed just as a function i.e. setSubmitFunc(handleSubmit) 
for some reason it is called and the reCaptcha is bypassed.

TODO - ReCaptcha error handling
*/

export default props => {
  //Effects
  const recaptcha = useRef(null);
  const [submitFunc, setSubmitFunc] = useState(null);
  // Entrance UI Flags
  const gatherDetails = props.entrance.gatherDetails;
  const loading = props.entrance.loading;
  // React Router location param
  const path = props.location.pathname;
  const { authError, entranceLoading } = props;
  const classes = styles();
  //Cloud Func
  const verifyRecaptcha = functions.httpsCallable("general-recaptcha");

  const reCaptcha = () => {
    return (
      <ReCAPTCHA
        ref={recaptcha}
        onErrored={() => authError({ code: "recaptcha" })}
        onChange={value => {
          let data = { token: value };
          recaptcha.current.reset();
          //Outside the verify as if the component is unloaded from the DOM before the reset
          //is fired, it will throw an error.
          verifyRecaptcha(data).then(res => {
            if (res.data.success) {
              submitFunc.submit();
            } else {
              authError({ code: "recaptcha" });
            }
          });
        }}
        style={{ zIndex: "999" }}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
    );
  };

  const renderForm = () => {
    if (gatherDetails) {
      return (
        <GatherDetails
          loading={loading}
          classes={classes}
          gatherDetailsComplete={props.gatherDetailsComplete}
          gatherDetails={props.gatherDetails}
          authLogout={props.authLogout}
        />
      );
    } else if (path === "/signout") {
      return <SignOut classes={classes} authLogout={props.authLogout} />;
    } else if (path === "/signin") {
      return (
        <Fragment>
          {reCaptcha()}
          <SignIn
            loading={loading}
            entranceLoading={entranceLoading}
            classes={classes}
            authAttempt={props.authAttempt}
            auth={props.auth}
            path={path}
            recaptcha={recaptcha}
            setSubmitFunc={setSubmitFunc}
            openSnackbar={props.openSnackbar}
            authError={props.authError}
          />
        </Fragment>
      );
    } else if (path === "/signup") {
      return (
        <Fragment>
          {reCaptcha()}
          <SignUp
            loading={loading}
            entranceLoading={entranceLoading}
            classes={classes}
            authAttempt={props.authAttempt}
            auth={props.auth}
            path={path}
            recaptcha={recaptcha}
            setSubmitFunc={setSubmitFunc}
          />
        </Fragment>
      );
    } else if (path === "/resetpassword") {
      return (
        <Fragment>
          {reCaptcha()}
          <ResetPassword
            classes={classes}
            recaptcha={recaptcha}
            setSubmitFunc={setSubmitFunc}
            openSnackbar={props.openSnackbar}
            authError={props.authError}
          />
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <div className={classes.wrapper}>
        <div className={classes.sideDecoration}>
          <ReactSVG src={whiteLogo} className={classes.sideDecorationIcon} />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.contentHeader}>
            <Link to="/">
              <ReactSVG className={classes.contentHeaderLogo} src={blueLogo} />
            </Link>
          </div>
          <div className={classes.contentContainer}>{renderForm()}</div>
        </div>
      </div>
      <Snackbar />
    </Fragment>
  );
};
