import React from "react";

//MUI
import Typography from "@material-ui/core/Typography";

export default props => {
  return (
    <Typography className={props.classes.textContainer} variant="body1">
      <p>
        <strong>Ascertive Acceptable Use Policy</strong>
      </p>
      <p>
        At Ascertive we're proud of the trust placed in us by our users. In
        exchange, we trust you to use our services responsibly.
      </p>
      <p>
        You agree not to misuse the Ascertive services ("Services") or help
        anyone else to do so. For example, you must not even try to do any of
        the following in connection with the Services:
      </p>
      <ul>
        <li>
          probe, scan, or test the vulnerability of any system or network;
        </li>
        <li>
          breach or otherwise circumvent any security or authentication
          measures;
        </li>
        <li>
          access, tamper with, or use non-public areas or parts of the Services,
          or shared areas of the Services you haven't been invited to;
        </li>
        <li>
          interfere with or disrupt any user, host, or network, for example by
          sending a virus, overloading, flooding, spamming, or mail-bombing any
          part of the Services;
        </li>
        <li>
          access, search, or create accounts for the Services by any means other
          than our publicly supported interfaces (for example, "scraping" or
          creating accounts in bulk);
        </li>
        <li>
          send unsolicited communications, promotions or advertisements, or
          spam;
        </li>
        <li>
          send altered, deceptive or false source-identifying information,
          including "spoofing" or "phishing";
        </li>
        <li>
          promote or advertise products or services other than your own without
          appropriate authorization;
        </li>
        <li>
          abuse referrals or promotions to get more storage space than deserved;
        </li>
        <li>circumvent storage space limits;</li>
        <li>sell the Services unless specifically authorized to do so;</li>
        <li>
          publish or share materials that are unlawfully pornographic or
          indecent, or that contain extreme acts of violence or terrorist
          activity, including terror propaganda;
        </li>
        <li>
          advocate bigotry or hatred against any person or group of people based
          on their race, religion, ethnicity, sex, gender identity, sexual
          orientation, disability, or impairment;
        </li>
        <li>
          harass or abuse Ascertive personnel or representatives or agents
          performing services on behalf of Ascertive;
        </li>
        <li>
          violate the law in any way, including storing, publishing or sharing
          material that's fraudulent, defamatory, or misleading; or
        </li>
        <li>violate the privacy or infringe the rights of others.</li>
      </ul>
    </Typography>
  );
};
