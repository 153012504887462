import React from "react";

//MUI
import Typography from "@material-ui/core/Typography";

export default props => {
  return (
    <Typography className={props.classes.textContainer} variant="body1">
      <p>
        <strong>Ascertive Terms of Service</strong>
      </p>
      <p>
        Posted: November 9th, 2019
        <br />
        Effective: November 9th, 2019
      </p>
      <p>
        Thanks for using Ascertive! Our mission is to create a more efficient
        way of storing, sharing and monitoring your certification and progress
        as a seafarer, by providing a platform to keep your content safe and
        accessible. These terms of service ("<strong>Terms</strong>") cover your
        use and access to our website, application and services ("
        <strong>Services</strong>"). Your agreement is with Ascertive Limited no
        matter where you reside. Our{" "}
        <a href="https://www.ascertive.com/information/privacy">
          Privacy Policy
        </a>{" "}
        explains how we collect and use your information while our{" "}
        <a href="https://www.ascertive.com/information/acceptable_use">
          Acceptable Use Policy
        </a>{" "}
        outlines your responsibilities when using our Services. By using our
        Services, you&rsquo;re agreeing to be bound by these Terms, our{" "}
        <a href="https://www.ascertive.com/information/privacy">
          Privacy Policy
        </a>
        , and{" "}
        <a href="https://www.ascertive.com/information/acceptable_use">
          Acceptable Use Policy
        </a>
        .
      </p>
      <p>
        <strong>Your Stuff &amp; Your Permissions</strong>
      </p>
      <p>
        When you use our Services, you provide us with things like your
        certification, documents, files, records, and so on ("
        <strong>Your</strong>
        <strong> Stuff</strong>"). Your Stuff is yours. These Terms don&rsquo;t
        give us any rights to Your Stuff except for the limited rights that
        enable us to offer the Services.
      </p>
      <p>
        We need your permission to do things like hosting Your Stuff, backing it
        up, and sharing it when you ask us to.
      </p>
      <p>
        Our Services also provide you with features like sharing, searching,
        image thumbnails, document previews, optical character recognition
        (OCR), easy sorting and organization, and personalization to help
        monitor your certification. To provide these and other features,
        Ascertive accesses, stores, and scans Your Stuff. You give us permission
        to do those things, and this permission extends to our affiliates and
        trusted third parties we work with.
      </p>
      <p>
        <strong>Your Responsibilities</strong>
      </p>
      <p>
        Your use of our Services must comply with our{" "}
        <a href="https://www.ascertive.com/information/acceptable_use">
          Acceptable Use Policy
        </a>
        . Content in the Services may be protected by others&rsquo; intellectual
        property rights. Please don&rsquo;t copy, upload, download, or share
        content unless you have the right to do so.
      </p>
      <p>
        Ascertive may review your conduct and content for compliance with these
        Terms and our{" "}
        <a href="https://www.ascertive.com/information/acceptable_use">
          Acceptable Use Policy
        </a>
        . We aren&rsquo;t responsible for the content people post and share via
        the Services.
      </p>
      <p>
        Help us keep Your Stuff protected. Safeguard your password to the
        Services, and keep your account information current. Don&rsquo;t share
        your account credentials or give others access to your account.
      </p>
      <p>
        You may use our Services only as permitted by applicable law, including
        export control laws and regulations.
      </p>
      <p>
        <strong>Beta Services</strong>
      </p>
      <p>
        We sometimes release products and features that we&rsquo;re still
        testing and evaluating (&ldquo;Beta Services&rdquo;). Beta Services are
        labeled &ldquo;alpha,&rdquo; &ldquo;beta,&rdquo; &ldquo;preview,&rdquo;
        &ldquo;early access,&rdquo; or &ldquo;evaluation&rdquo; (or with words
        or phrases with similar meanings) and may not be as reliable. Beta
        Services are made available so that we can collect user feedback, and by
        using our Beta Services, you agree that we may contact you to collect
        such feedback.
      </p>
      <p>
        Beta Services are confidential until official launch. If you use any
        Beta Services, you agree not to disclose any information about those
        Services to anyone else without our permission.
      </p>
      <p>
        <strong>Our Stuff</strong>
      </p>
      <p>
        The Services are protected by copyright, trademark, and other New
        Zealand and foreign laws. These Terms don&rsquo;t grant you any right,
        title, or interest in the Services, others&rsquo; content in the
        Services, Ascertive trademarks, logos and other brand features. We
        welcome feedback, but note that we may use comments or suggestions
        without any obligation to you.
      </p>
      <p>
        <strong>Copyright</strong>
      </p>
      <p>
        We respect the intellectual property of others and ask that you do too.
        We respond to notices of alleged copyright infringement if they comply
        with the law, and such notices should be reported. We reserve the right
        to delete or disable content alleged to be infringing and terminate
        accounts of repeat infringers. Please contact our designated person for
        notice of alleged copyright infringement on the Services at:{" "}
        <a href="mailto:copyright@ascertive.com">copyright@ascertive.com</a>
      </p>
      <p>
        <strong>Termination</strong>
      </p>
      <p>
        You&rsquo;re free to stop using our Services at any time. We reserve the
        right to suspend or terminate your access to the Services with notice to
        you if:
      </p>
      <p>(a) you&rsquo;re in breach of these Terms,</p>
      <p>
        (b) your use of the Services would cause a real risk of harm or loss to
        us or other users, or
      </p>
      <p>
        (c) you don&rsquo;t have a Paid Account and haven't accessed our
        Services for 12 consecutive months.
      </p>
      <p>
        We&rsquo;ll provide you with reasonable advance notice via the email
        address associated with your account to remedy the activity that
        prompted us to contact you and give you the opportunity to export Your
        Stuff from our Services. If after such notice you fail to take the steps
        we ask of you, we&rsquo;ll terminate or suspend your access to the
        Services.
      </p>
      <p>We won&rsquo;t provide notice before termination where:</p>
      <p>(a) you&rsquo;re in material breach of these Terms,</p>
      <p>
        (b) doing so would cause us legal liability or compromise our ability to
        provide the Services to our other users, or
      </p>
      <p>(c) we're prohibited from doing so by law.</p>
      <p>
        <strong>Discontinuation of Services</strong>
      </p>
      <p>
        We may decide to discontinue the Services in response to unforeseen
        circumstances beyond Ascertive&rsquo;s control or to comply with a legal
        requirement. If we do so, we&rsquo;ll give you reasonable prior notice
        so that you can export Your Stuff from our systems.
      </p>
      <p>
        <strong>Services</strong> <strong>&ldquo;AS</strong>
        <strong> IS&rdquo;</strong>
      </p>
      <p>
        We strive to provide great Services, but there are certain things that
        we can't guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW, ASCERTIVE
        AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES,
        EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED
        "AS IS." WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR
        A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Some places don&rsquo;t
        allow the disclaimers in this paragraph, so they may not apply to you.
      </p>
      <p>
        <strong>Limitation of Liability</strong>
      </p>
      <p>
        WE DON&rsquo;T EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE
        ILLEGAL TO DO SO&mdash;THIS INCLUDES ANY LIABILITY FOR ASCERTIVE&rsquo;S
        OR ITS AFFILIATES&rsquo; FRAUD OR FRAUDULENT MISREPRESENTATION IN
        PROVIDING THE SERVICES. IN COUNTRIES WHERE THE FOLLOWING TYPES OF
        EXCLUSIONS AREN&rsquo;T ALLOWED, WE'RE RESPONSIBLE TO YOU ONLY FOR
        LOSSES AND DAMAGES THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR
        FAILURE TO USE REASONABLE CARE AND SKILL OR OUR BREACH OF OUR CONTRACT
        WITH YOU. THIS PARAGRAPH DOESN&rsquo;T AFFECT CONSUMER RIGHTS THAT CAN'T
        BE WAIVED OR LIMITED BY ANY CONTRACT OR AGREEMENT.
      </p>
      <p>
        IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED,
        ASCERTIVE, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WON&rsquo;T BE
        LIABLE FOR:
      </p>
      <p>
        i. ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR
        CONSEQUENTIAL DAMAGES, OR
      </p>
      <p>
        ii. ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL
        THEORY.
      </p>
      <p>
        THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT
        ASCERTIVE OR ANY OF ITS AFFILIATES HAS BEEN WARNED OF THE POSSIBILITY OF
        SUCH DAMAGES.
      </p>
      <p>
        IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS, OR RE-SALE
        PURPOSE, ASCERTIVE, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WILL HAVE
        NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS
        INTERRUPTION, OR LOSS OF BUSINESS OPPORTUNITY. ASCERTIVE AND ITS
        AFFILIATES AREN&rsquo;T RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR
        OFFLINE, OF ANY USER OF THE SERVICES.
      </p>
      <p>
        OTHER THAN FOR THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS
        DESCRIBED IN THIS SECTION), WE LIMIT OUR LIABILITY TO YOU TO THE GREATER
        OF $20 USD OR 100% OF ANY AMOUNT YOU'VE PAID UNDER YOUR CURRENT SERVICE
        PLAN WITH ASCERTIVE.
      </p>
      <p>
        <strong>Resolving Disputes</strong>
      </p>
      <p>
        <em>Let&rsquo;s Try to Sort Things Out First.</em> We want to address
        your concerns without needing a formal legal case. Before filing a claim
        against Ascertive, you agree to try to resolve the dispute informally by
        contacting{" "}
        <a href="mailto:dispute-notice@ascertive.com">
          dispute-notice@ascertive.com
        </a>
        . We&rsquo;ll try to resolve the dispute informally by contacting you
        via email. If a dispute is not resolved within 15 days of submission,
        you or Ascertive may bring a formal proceeding.
      </p>
      <p>
        <em>Judicial Forum for Disputes.</em> You and Ascertive agree that any
        judicial proceeding to resolve claims relating to these Terms or the
        Services will be brought in the courts of Auckland, New Zealand. Both
        you and Ascertive consent to venue and personal jurisdiction in such
        courts. If you reside in a country (for example, a member state of the
        European Union) with laws that give consumers the right to bring
        disputes in their local courts, this paragraph doesn&rsquo;t affect
        those requirements.
      </p>
      <p>
        <strong>Controlling Law</strong>
      </p>
      <p>
        These Terms will be governed by New Zealand law except for its conflicts
        of laws principles. However, some countries (including those in the
        European Union) have laws that require agreements to be governed by the
        local laws of the consumer's country. This paragraph doesn&rsquo;t
        override those laws.
      </p>
      <p>
        <strong>Entire Agreement</strong>
      </p>
      <p>
        These Terms constitute the entire agreement between you and Ascertive
        with respect to the subject matter of these Terms, and supersede and
        replace any other prior or contemporaneous agreements, or terms and
        conditions applicable to the subject matter of these Terms. These Terms
        create no third party beneficiary rights.
      </p>
      <p>
        <strong>Waiver, Severability &amp; Assignment</strong>
      </p>
      <p>
        Ascertive's failure to enforce a provision is not a waiver of its right
        to do so later. If a provision is found unenforceable, the remaining
        provisions of the Terms will remain in full effect and an enforceable
        term will be substituted reflecting our intent as closely as possible.
        You may not assign any of your rights under these Terms, and any such
        attempt will be void. Ascertive may assign its rights to any of its
        affiliates or subsidiaries, or to any successor in interest of any
        business associated with the Services.
      </p>
      <p>
        <strong>Modifications</strong>
      </p>
      <p>We may revise these Terms from time to time to better reflect:</p>
      <p>(a) changes to the law,</p>
      <p>(b) new regulatory requirements, or</p>
      <p>(c) improvements or enhancements made to our Services.</p>
      <p>
        If an update affects your use of the Services or your legal rights as a
        user of our Services, we&rsquo;ll notify you prior to the update's
        effective date by sending an email to the email address associated with
        your account or via an in-product notification. These updated terms will
        be effective no less than 30 days from when we notify you.
      </p>
      <p>
        If you don&rsquo;t agree to the updates we make, please cancel your
        account before they become effective. By continuing to use or access the
        Services after the updates come into effect, you agree to be bound by
        the revised Terms.
      </p>
    </Typography>
  );
};
