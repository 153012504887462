import React, { useState } from "react";
import { captureException } from "@sentry/react";
import { Formik, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { auth } from "../../../../index";

import Email from "@material-ui/icons/EmailRounded";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import renderCustomInput from "../../../common/forms/CustomInput";
import renderErrorMessage from "../../../common/forms/messages/ErrorMessage";

const errorSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Required")
});

// Reset Password opens in a dialog and the handleSubmit is executed through the recaptcha
// element which is on the overall Entrance Component

export default props => {
  const [disabled, setDisabled] = useState(false);
  const { classes, recaptcha, setSubmitFunc, openSnackbar } = props;

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={errorSchema}
        onSubmit={(values, actions) => {
          //Remove whitespace
          values.email = values.email.trim();
          auth
            .sendPasswordResetEmail(values.email)
            .then(() => {
              openSnackbar({
                type: "success",
                message: "A link has been sent to your inbox!"
              });
              actions.setSubmitting(false);
              actions.resetForm();
              setDisabled(false);
            })
            .catch(err => {
              //Dispatch an authError - snackbar reducer will identify correct error code and corresponding message
              props.authError(err);
              actions.setSubmitting(false);
              actions.resetForm();
              captureException(err, scope => {
                scope.setTag("specifics", "Send password reset error");
              });
            });
        }}
      >
        {({ handleSubmit, isSubmitting, validateForm, isValid }) => (
          <form className={classes.resetPasswordContainer}>
            <Field
              component={renderCustomInput}
              name="email"
              label="Email"
              icon={<Email />}
              type="text"
            />
            <div
              className={`${classes.errorContainer} ${classes.marginBottom}`}
            >
              <ErrorMessage name="email" component={renderErrorMessage} />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled || isSubmitting}
                onClick={() => {
                  if (!isSubmitting) {
                    validateForm().then(() => {
                      if (isValid) {
                        setDisabled(true);
                        setSubmitFunc({ submit: handleSubmit });
                        recaptcha.current.execute();
                      }
                    });
                  }
                }}
              >
                Reset Password
              </Button>
              {isSubmitting && (
                <CircularProgress size={24} className={classes.circleLoader} />
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h4" component="h2">
          Reset password
        </Typography>
      </div>
      <div className={classes.formTextContainer}>
        <Typography
          variant="body1"
          className={classes.marginBottom}
          align="center"
        >
          Please enter the email associated with your account and we'll send a
          reset link to your email.
        </Typography>
        <Typography
          variant="body1"
          className={classes.marginBottom}
          align="center"
        >
          If you use sign in with via a social account, please provide the email
          associated with your Google or Facebook account.
        </Typography>
      </div>
      {renderForm()}
      <div className={classes.linksContainer}>
        <Link className={classes.link} to="/signin">
          <Typography variant="body2">Back to sign in</Typography>
        </Link>
      </div>
    </div>
  );
};
