import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  wrapper: {
    display: "flex",
    height: "100vh",
    width: "100%",
    position: "relative",
    overflowY: "hidden"
  },
  sideDecoration: {
    width: "30%",
    maxWidth: "30rem",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 700px)": {
      display: "none"
    }
  },
  sideDecorationIcon: {
    height: "auto",
    width: "6rem",
    "& div": {
      "& svg": {
        "& path": {
          fill: "white"
        }
      }
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "white"
  },
  contentHeader: {
    height: "4rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 3rem",
    "@media(max-width: 700px)": {
      padding: "0rem 1rem"
    }
  },
  contentHeaderLogo: {
    height: "0",
    width: "4rem"
  },
  contentContainer: {
    display: "flex",
    flex: 1,
    padding: "0 3rem",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 700px)": {
      padding: "5rem 1rem"
    }
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "30rem",
    width: "100%"
  },
  titleContainer: {
    textAlign: "center",
    marginBottom: "1rem"
  },
  title: {
    fontWeight: 700
  },
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem 0"
  },
  link: {
    "&, &:link, &:visited": {
      textDecoration: "none",
      cursor: "pointer"
    },
    "&:hover p": {
      color: theme.palette.secondary.main
    },
    "&:not(last-child)": {
      marginBottom: "0.5rem"
    }
  },

  formWrapper: {
    width: "40%",
    minWidth: "500px",
    "@media (max-width: 700px)": {
      width: "100%",
      minWidth: "0"
    }
  },

  // SLIDING ANIMATION DON'T TOUCH
  signInActive: {},
  signInHidden: {
    transform: "translateY(-100%)",
    transition: "transform 1s"
  },
  signUpActive: {
    transform: "translateY(-100%)",
    transition: "transform 1s"
  },
  signUpHidden: {
    transform: "translateY(100%)",
    transition: "transform 1s"
  },
  // SLIDING ANIMATION DON'T TOUCH
  imageContainer: {
    position: "relative",
    width: "60%",
    "@media (max-width: 700px)": {
      display: "none"
    }
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "50% 50%"
  },
  header: {
    padding: "1rem 3rem",
    height: "5rem",
    borderBottom: "1px solid #DEDEDE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  logo: {
    height: "2rem",
    width: "auto"
  },
  marginBottom: {
    marginBottom: "1rem"
  },
  stepper: {
    width: "75%",
    marginLeft: "1rem",
    backgroundColor: "#fafafa",
    padding: "0"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  socialContainer: {
    width: "100%",
    paddingBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: ".75rem",
    height: "6.5rem"
  },
  socialButton: {
    minWidth: "280px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0.5rem",
    minHeight: "fit-content",
    padding: "10px 15px",
    border: "1px solid lightgrey"
  },
  facebookButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  googleButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  socialIcon: {
    height: "1.5rem",
    width: "24px",
    marginRight: "2rem"
  },
  facebookIcon: {
    fill: "white"
  },
  formTextContainer: {
    textAlign: "center",
    marginBottom: "1rem"
  },
  signUpForm: {
    height: "23rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  // signInForm: {
  //   height: "18rem",
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   alignItems: "center"
  // },
  gatherDetailsForm: {
    height: "33rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  errorContainer: {
    width: "100%",
    height: "22px",
    display: "flex",
    margin: "0.75rem 0"
  },
  buttonContainer: {
    position: "relative",
    width: "100%"
  },
  submitButton: {
    width: "100%",
    height: "3rem",
    minWidth: "3rem"
  },
  switchFormContainer: {
    height: "4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "right"
  },
  circleLoader: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  signOutImageContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 0"
  },
  signOutImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    objectPosition: "50% 50%"
  },
  signOutButtonContainer: {
    height: "3rem",
    width: "100%",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  signOutButton: {
    width: "40%",
    border: `1px solid ${theme.palette.primary.main}`
  },
  forgotContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem"
  },
  resetPasswordContainer: {
    display: "flex",
    flexDirection: "column"
  },
  bigButton: {
    padding: "10px 23px"
  }
}));
