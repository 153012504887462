//Base
import React from "react";
import { createReduxEnhancer } from "@sentry/react";

//Redux
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";

//Persist
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

import reducers from "./reducers";

import { entranceOperations } from "./app/pages/entrance/duck/index";

// ==========================================================================
// REDUX STORE SETUP
// ==========================================================================

//Config - state merge level one - not deep merge
const persistConfig = {
  key: "root",
  storage
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, reducers);
const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: state => {
    let transformedState;
    if (state.auth.authObject) {
      transformedState = {
        ...state,
        auth: {
          ...state.auth,
          authObject: {
            ...state.auth.authObject,
            additionalUserInfo: {
              ...state.auth.authObject.additionalUserInfo,
              profile: "Redacted"
            },
            credential: "Redacted",
            user: {
              ...state.auth.authObject.user,
              apiKey: "Redacted",
              authDomain: "Redacted",
              stsTokenManager: "Redacted"
            }
          }
        }
      };
    } else {
      transformedState = {
        ...state
      };
    }

    return transformedState;
  }
});

// What is served to the store \/

export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(sagaMiddleware),
    sentryReduxEnhancer
  )
);

export const persistor = persistStore(store);
sagaMiddleware.run(entranceOperations.authFlow);

// persistor.purge();
//Exported as a wrapper for testing purposes

export default props => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {props.children}
      </PersistGate>
    </Provider>
  );
};
