// ==========================================================================
// REACT, REDUX, COMPONENTS AND OTHER RELATED PACKAGES
// ==========================================================================
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

//Redux
import Store, { store } from "./store";

// Firebase and Firestore
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";

// Stylesheet / Theme Imports
import theme from "./utils/styling/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./utils/styling/tailwind.css";

// Components
import App from "./app/App";
import FallbackComponent from "./utils/sentry/sentry";

// ==========================================================================
// FIREBASE, FIRESTORE SETUP
// ==========================================================================

//Initialization
const firebaseConfig = {
  // ascertive-test
  apiKey: process.env.REACT_APP_APPLICATION,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize an instance of firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// ==========================================================================
// SENTRY
// ==========================================================================

// if (process.env.NODE_ENV === "production") {
Sentry.init({
  dsn:
    // change this to prod link
    "https://9b577f8ea4b147d8b03d1eed9cdf81d8@o252045.ingest.sentry.io/5497130",
  normalizeDepth: 10,
  debug: true,
  maxBreadcrumbs: 50
});
// }

// Used to set user state for Sentry (called by the error boundary upon error);
const user = () => {
  const auth = store.getState().auth.authObject;

  if (auth) {
    Sentry.setUser({ email: auth.user.email, id: auth.user.uid });
  }
}
if (process.env.NODE_ENV === "production") {
  firebase.analytics();
}
firebase.performance();

// ==========================================================================
//                              EXPORTS
// ==========================================================================

export const db = firebase.firestore();
export const storageBucket = firebase.storage();
export const functions = firebase.functions();
export const auth = firebase.auth();
export const analytics = firebase.analytics();

export const facebook = new firebase.auth.FacebookAuthProvider();
export const google = new firebase.auth.GoogleAuthProvider();

// ==========================================================================
// REACTDOM RENDER
// ==========================================================================

ReactDOM.render(
  <Store>
    <CssBaseline>
      <MuiThemeProvider theme={theme}>
        <Sentry.ErrorBoundary
          showDialog
          fallback={<FallbackComponent />}
          beforeCapture={() => user()}
        >
          <App />
        </Sentry.ErrorBoundary>
      </MuiThemeProvider>
    </CssBaseline>
  </Store>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
