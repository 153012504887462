import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main
  },
  contentContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    "@media (max-width: 800px)": {
      flexDirection: "column",
      marginBottom: "2rem",
      justifyContent: "center"
    }
  },
  dashboardContentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  },
  imageContainer: {
    position: "relative",
    height: "25rem"
  },
  image: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  dashboardHuge: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.secondary.main,
    zIndex: 1,
    fontSize: "15rem",
    marginRight: "3rem",
    "@media (max-width: 600px)": {
      marginRight: "0rem",
      fontSize: "11rem"
    }
  },

  huge: {
    fontSize: "15rem",
    marginRight: "3rem",
    "@media (max-width: 800px)": {
      marginRight: "0rem",
      fontSize: "11rem"
    }
  },
  text: {
    "@media (max-width: 800px)": {
      textAlign: "center"
    }
  },
  white: {
    color: "white"
  }
}));
