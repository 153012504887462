import React from "react";
import { ReactSVG } from "react-svg";

import styles from "../../app/pages/entrance/EntranceComponent.style";

import Typography from "@material-ui/core/Typography";
import CustomButton from "../../app/common/button/button";

import whiteLogo from "../../assets/logo/whiteLogo.svg";
import blueLogo from "../../assets/logo/blueLogo.svg";

export default props => {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.sideDecoration}>
        <ReactSVG src={whiteLogo} className={classes.sideDecorationIcon} />
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.contentHeader}>
          <ReactSVG className={classes.contentHeaderLogo} src={blueLogo} />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.formContainer}>
            <Typography gutterBottom align="center" variant="h3" component="h1">
              Oops something went wrong!
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              You managed to break the website! Not to worry, our developers
              have been alerted and will get to fixing this issue as soon as
              possible.
            </Typography>
            <Typography
              style={{ marginBottom: "2rem" }}
              gutterBottom
              variant="body1"
              component="p"
            >
              <strong>
                Please press the button below to reset the website. This will
                require you to sign in again.
              </strong>
            </Typography>
            <div style={{ textAlign: "center" }}>
              <CustomButton
                color="green"
                buttonHandler={() => {
                  window.location.href = `${
                    process.env.NODE_ENV === "production"
                      ? "https://ascertive.com"
                      : "https://localhost:3000"
                  }/signout`;
                }}
                className={classes.bigButton}
              >
                Reset
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};