import { combineReducers } from "redux";

import authReducer from "./app/duck/authReducer";
import userReducer from "./app/duck/userReducer";
import entranceReducer from "./app/pages/entrance/duck/index";
import certificatesReducer from "./app/pages/dashboard/certificates/duck/index";
import seatimeReducer from "./app/pages/dashboard/seatime/duck/index";
import snackbarReducer from "./app/common/snackbar/duck/index";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  entrance: entranceReducer,
  certificates: certificatesReducer,
  seatime: seatimeReducer,
  snackbar: snackbarReducer
});

export default rootReducer;

// dashboard reducer - combine tutorial reducer

// error reducer

// beta reducer for beta testing.

// user reducer
// just contains the instance, a local snapshot of the user in the database that will change when fields are updated etc.

// auth reducer
// snapshot of the firebase auth object

// dashboard reducer:
// responsible for ui changes on the dashboard.

// global error reducer
// responsible for showing and transmitting error handling

// beta reducer
// just for the beta version of the app.
