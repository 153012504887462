import { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

//Logo
import blueLogo from "../../../../assets/logo/blueLogoSVG.svg";
import whiteLogo from "../../../../assets/logo/whiteLogo.svg";
import LogoText from "../../../../assets/logo/Ascertive_logo+text_100x100px.png";

//Icons
import Login from "../../../../assets/iconsSVG/login.svg";
import Group from "../../../../assets/iconsSVG/group.svg";
import Send from "../../../../assets/iconsSVG/send.svg";
import Home from "../../../../assets/iconsSVG/home.svg";
import Settings from "../../../../assets/iconsSVG/settings.svg";
import SignOut from "@material-ui/icons/ExitToAppRounded";
import Default from "../../../../assets/default.png";

export default props => {
  const { user, white } = props;
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(false);

  return (
    <div className="z-20">
      <nav
        className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
        aria-label="Global"
      >
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link to="/">
              <span className="sr-only">Ascertive</span>
              <img
                className="h-4 w-auto sm:h-6"
                src={white ? whiteLogo : blueLogo}
                alt="Ascertive Logo"
              />
            </Link>
            <div className="-mr-2 flex items-center md:hidden">
              {user?.instance ? (
                <button
                  className="mr-2 inline-block relative"
                  onClick={() => setOpen(!open)}
                >
                  <img
                    className="h-10 w-10 rounded-full"
                    src={user?.instance?.details.photoURL || Default}
                    alt="User"
                  />
                  <div className="absolute bottom-0 right-0 block h-4 w-4 rounded-full ring-2 ring-white text-primary bg-white">
                    {/* <!-- Heroicon name: solid/chevron-down --> */}
                    <svg
                      className="h-4 w-4 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-primary hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  aria-expanded="false"
                  onClick={() => setOpen(!open)}
                >
                  <span className="sr-only">Open main menu</span>
                  {/* <!-- Heroicon name: outline/menu --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div className="hidden md:block md:ml-10 md:space-x-10">
            <Link
              to="/aboutus"
              className={`font-medium ${
                white ? "text-white" : "text-gray-500"
              } hover:text-gray-900`}
            >
              About Us
            </Link>

            <Link
              to="/contactus"
              className={`font-medium ${
                white ? "text-white" : "text-gray-500"
              } hover:text-gray-900`}
            >
              Contact
            </Link>
          </div>
        </div>
        <div className="hidden md:block">
          {user?.instance ? (
            <div className=" relative">
              <div>
                <button
                  type="button"
                  className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50 shadow-md ring-1 ring-black ring-opacity-5"
                  id="user-menu"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={() => setMenu(!menu)}
                  onBlur={() => setMenu(false)}
                >
                  <img
                    className="h-9 w-9 rounded-full object-cover object-center"
                    src={user?.instance?.details.photoURL || Default}
                    alt="User"
                  />
                  <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                    <span className="sr-only">Open user menu for </span>
                    {user?.instance?.details.firstName}
                  </span>
                  {/* <!-- Heroicon name: solid/chevron-down --> */}
                  <svg
                    className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <Transition
                show={menu}
                appear={true}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                {ref => (
                  <div
                    ref={ref}
                    className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <Link
                      to="/dashboard/overview"
                      className="w-full inline-flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      <img
                        src={Home}
                        className="-ml-0.5 mr-4 h-4 w-4"
                        alt="Home"
                      />
                      Dashboard
                    </Link>
                    <Link
                      to="/dashboard/settings"
                      className="w-full inline-flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      <img
                        src={Settings}
                        className="-ml-0.5 mr-4 h-4 w-4"
                        alt="Settings"
                      />
                      Settings
                    </Link>
                    <Link
                      to="/signout"
                      className="w-full inline-flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      <SignOut className="-ml-0.5 mr-4 h-4 w-4" />
                      Sign Out
                    </Link>
                  </div>
                )}
              </Transition>
            </div>
          ) : (
            <div className="text-right">
              <span className="inline-flex">
                <Link
                  to="/signin"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Sign in
                </Link>
              </span>
              <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                <Link
                  to="/signup"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:text-gray-200"
                >
                  Sign up
                </Link>
              </span>
            </div>
          )}
        </div>
      </nav>

      {/* <!-- Mobile menu, show/hide based on menu open state. --> */}
      <Transition
        show={open}
        appear={true}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {ref => (
          <div
            ref={ref}
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-5 w-auto"
                    src={LogoText}
                    alt="Ascertive Logo"
                  />
                </div>
                <div className="-mr-2">
                  <button
                    onClick={() => setOpen(!open)}
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-primary hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close main menu</span>
                    {/* <!-- Heroicon name: outline/x --> */}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                <Link
                  to="/aboutus"
                  className="w-full inline-flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  <img
                    src={Group}
                    className="-ml-0.5 mr-4 h-4 w-4"
                    alt="About Us"
                  />
                  About Us
                </Link>

                <Link
                  to="/contactus"
                  className="w-full inline-flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  <img
                    src={Send}
                    className="-ml-0.5 mr-4 h-4 w-4"
                    alt="Contact"
                  />
                  Contact
                </Link>

                {user.instance ? (
                  <Link
                    to="/signout"
                    className="w-full inline-flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  >
                    <SignOut className="-ml-0.5 mr-4 h-4 w-4" />
                    Sign out
                  </Link>
                ) : (
                  <Link
                    to="/signin"
                    className="w-full inline-flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  >
                    <img
                      src={Login}
                      className="-ml-0.5 mr-4 h-4 w-4"
                      alt="Sign In"
                    />
                    Sign in
                  </Link>
                )}
              </div>
              {user.instance ? (
                <Link
                  to="/dashboard/overview"
                  className="uppercase tracking-wider block w-full px-5 py-3 text-center font-medium text-white bg-primary hover:text-gray-200 border-l-2 border-r-2 border-b-2 border-white rounded-b-lg"
                >
                  Dashboard
                </Link>
              ) : (
                <Link
                  to="/signup"
                  className="uppercase tracking-wider block w-full px-5 py-3 text-center font-medium text-white bg-primary hover:text-gray-200"
                >
                  Sign up
                </Link>
              )}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};
