import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

function pointsToPixels(points) {
  return (points * 96) / 72;
}

function kerningToPixels(kerning, fontSize) {
  return (kerning * fontSize) / 1000;
}

let theme = createMuiTheme({
  //Ascertive Brand Guidelines
  palette: {
    //Primary colour, texts, icons, outlines - dark blue
    primary: {
      main: "#273C61"
    },
    //Call to action buttons - light blue
    secondary: {
      main: "#1E82E1"
    },
    //Call to action buttons - green
    success: {
      main: "#4BD131"
    },
    //Body text (attention) - red
    error: {
      main: "#E51A19"
    },
    //Background - off white
    backgroundColor: {
      main: "#F6F6F6"
    }
  },
  typography: {
    fontFamily: ["Montserrat", "acumin-pro-wide", "sans-serif"],
    htmlFontSize: 16,
    fontSize: 14,
    //Headings
    h1: {
      // Montserrat 25pt (Bold) - Kerning 35
      fontFamily: ["Montserrat", "sans-serif"],
      // fontSize: `${pointsToPixels(25)}px`,
      letterSpacing: `${kerningToPixels(35, pointsToPixels(25))}px`,
      color: "#273C61"
    },
    //Sub Headings
    h2: {
      //18pt - Acumin Pro Wide (Semibold)  - Kerning 20
      fontFamily: ["acumin-pro-wide", "sans-serif"],
      // fontSize: `${pointsToPixels(18)}px`,
      letterSpacing: `${kerningToPixels(20, pointsToPixels(18))}px`,
      fontWeight: 600,
      fontStyle: "normal",
      color: "#273C61"
    },
    h3: {
      //16pt - Acumin Pro Wide (Semibold)  - Kerning 20
      fontFamily: ["acumin-pro-wide", "sans-serif"],
      // fontSize: `${pointsToPixels(16)}px`,
      letterSpacing: `${kerningToPixels(20, pointsToPixels(16))}px`,
      fontWeight: 600,
      fontStyle: "normal",
      color: "#273C61"
    },
    //Body Text
    body1: {
      //16pt - Acumin Pro Wide (Regular) - Kerning None
      fontFamily: ["acumin-pro-wide", "sans-serif"],
      // fontSize: `${pointsToPixels(16)}px`,
      letterSpacing: 0,
      fontWeight: 400,
      fontStyle: "normal",
      color: "#273C61"
    },
    body2: {
      //14pt - Acumin Pro Wide (Regular) - Kerning None
      fontFamily: ["acumin-pro-wide", "sans-serif"],
      // fontSize: `${pointsToPixels(14)}px`,
      letterSpacing: 0,
      fontWeight: 400,
      fontStyle: "normal",
      color: "#273C61"
    },
    //Body Text Bold
    subtitle1: {
      //16pt - Acumin Pro Wide (Semibold) - Kerning 20
      fontFamily: ["acumin-pro-wide", "sans-serif"],
      // fontSize: `${pointsToPixels(16)}px`,
      letterSpacing: `${kerningToPixels(20, pointsToPixels(16))}px`,
      fontWeight: 600,
      fontStyle: "normal"
    },
    subtitle2: {
      // Doubles as Widget Heading
      //14pt - Acumin Pro Wide (Semibold) - Kerning 20
      fontFamily: ["acumin-pro-wide", "sans-serif"],
      // fontSize: `${pointsToPixels(14)}px`,
      letterSpacing: `${kerningToPixels(20, pointsToPixels(14))}px`,
      fontWeight: 600,
      fontStyle: "normal"
    },
    //Button Text - Uppercase
    button: {
      //14pt - Montserrat (Bold) - Kerning 50-60
      fontFamily: ["Montserrat", "sans-serif"],
      // fontSize: `${pointsToPixels(14)}px`,
      letterSpacing: `${kerningToPixels(55, pointsToPixels(14))}px`,
      color: "white",
      textTransform: "uppercase"
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  drawerWidth: "14rem"
});

theme = responsiveFontSizes(theme);

export default theme;
// export const primaryColor = "#000000";
// export const successColor = "#4caf50";
