import types from "../pages/entrance/duck/types";

const initialState = {
  socialRedirect: false,
  attempt: false,
  authObject: null,
  authed: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SOCIAL_REDIRECT:
      return { ...state, socialRedirect: action.payload };
    case types.AUTH_ATTEMPT:
      return { ...state, attempt: true };
    case types.SAVE_USER_AUTH_OBJECT:
      return { ...state, authObject: action.payload };
    case types.AUTH_SUCCESS:
      return { ...state, attempt: false, authed: true, socialRedirect: false };
    case types.AUTH_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
