//=============================================
//             SEATIME UI TYPES
//=============================================

//Ship
const SEATIME_ADD_SHIP = "seatime_add_ship";
const SEATIME_EDIT_SHIP = "seatime_edit_ship";

//Entry
const SEATIME_ADD_ENTRY = "seatime_add_entry";
const SEATIME_EDIT_ENTRY = "seatime_edit_entry";
const SEATIME_ADD_ROTATION = "seatime_add_rotation";

//Common
const SEATIME_DISPLAY = "seatime_display";
const SEATIME_DELETE_DIALOG = "seatime_delete_dialog";

export default {
  SEATIME_ADD_SHIP,
  SEATIME_EDIT_SHIP,
  SEATIME_ADD_ENTRY,
  SEATIME_EDIT_ENTRY,
  SEATIME_ADD_ROTATION,
  SEATIME_DISPLAY,
  SEATIME_DELETE_DIALOG
};
