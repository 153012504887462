import React from "react";
import { Helmet } from "react-helmet";

import ExternalHeader from "../../common/headers/externalHeader/externalHeaderContainer";
import Footer from "../../common/footers/LandingFooter";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import styles from "./NomatchComponent.style";

// How to notify GoogleBot about 404 pages in Angular SPA?
//https://stackoverflow.com/questions/35989950/how-to-notify-googlebot-about-404-pages-in-angular-spa
// The best solution I could find after an exhaustive search

export default props => {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Ascertive | Page Not Found</title>
        <meta name="title" content="Ascertive | Page Not Found" />
        <meta name="description" content="404" />
        <meta name="robot" content="noindex" />
      </Helmet>

      <div className="pt-6 pb-6 bg-white font-serif">
        <ExternalHeader location={props.location} />
      </div>
      <Container className={classes.contentContainer}>
        <Typography
          variant="h1"
          component="h1"
          className={`${classes.huge} ${classes.white}`}
        >
          404
        </Typography>
        <div>
          <Typography
            variant="h6"
            className={`${classes.text} ${classes.white}`}
            gutterBottom
          >
            Congratulations you managed to find our 404 page!
          </Typography>
          <Typography
            variant="h6"
            className={`${classes.text} ${classes.white}`}
          >
            The page you're looking for does not exist, but don't worry - this
            is not a dead end. Just use the navigation at the top to see more
            options
          </Typography>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
