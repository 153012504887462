//Firebase
import firebase from "firebase/app";
import { auth, db, facebook, google } from "../../../../index";
import history from "../../../../utils/routes/history";
import { store, persistor } from "../../../../store";

//Analytics
import { analytics_Auth } from "../../../../utils/analytics/events";

//Saga
import {
  call,
  put,
  take,
  cancel,
  takeLatest,
  select
} from "redux-saga/effects";

//Types and Action Creators
import types from "./types";
import {
  saveUserAuthObject,
  saveUserDatabaseInstance,
  socialRedirect,
  authAttempt,
  authError,
  authSuccess,
  authLogout,
  entranceLoading,
  gatherDetails,
  gatherDetailsComplete
} from "./actions";

//=====================================
//        HELPER FUNCTIONS
//=====================================

const getSocialRedirectState = () => {
  return store.getState().auth.socialRedirect;
};

const setPersistence = () => {
  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
};

const signUpUser = ({ email, password }) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

const signInUser = ({ email, password }) => {
  return auth.signInWithEmailAndPassword(email, password);
};

const googleAuth = () => {
  return auth.signInWithRedirect(google);
};

const facebookAuth = () => {
  return auth.signInWithRedirect(facebook);
};

const getRedirectResult = () => {
  return auth.getRedirectResult();
};

const readUserInstance = ({ userAuthObject }) => {
  return db
    .collection("users")
    .doc(userAuthObject.user.uid)
    .get();
};

const writeUserInstance = ({ userInstance, uid }) => {
  db.collection("users")
    .doc(uid)
    .set(userInstance);
};

const sendEmailVerification = () => {
  return auth.currentUser
    .sendEmailVerification()
    .catch(err => console.log(err));
};

function buildUserInstance({ userAuthObject, payload }) {
  const { firstName, lastName, dob, phone, homePort, timeZone } = payload;
  const { photoURL, displayName, email, uid } = userAuthObject.user;

  const userInstance = {
    details: {
      emailNotifications: true,
      firstName,
      lastName,
      displayName,
      dob,
      email,
      homePort,
      phone,
      photoURL,
      timeZone
    },
    flags: {
      tutorial: {
        certificates: true,
        seatime: true,
        tracker: true,
        overview: true
      }
    },
    certificates: {
      certificates: []
    },
    seatime: {
      entries: [],
      vessels: []
    },
    tracker: {
      genericKey: "",
      specificKeys: [],
      trackerData: []
    },
    notifications: {
      notifications: []
    }
  };
  return { userInstance, uid };
}

//=====================================
//              SAGAS
//=====================================

// Whenever and authError is dispatched, the user is logged out if they are logged in
// auth, user and entrance reducers are reset

function* authFlow() {
  while (true) {
    const task = yield takeLatest(types.AUTH_ATTEMPT, authorizeSaga);
    const action = yield take([types.AUTH_ERROR, types.AUTH_LOGOUT]);
    if (action.type === types.AUTH_ERROR) {
      // cancels authorize saga on any auth error
      yield cancel(task);
    } else if (action.type === types.AUTH_LOGOUT) {
      // cancels authorize saga if running
      yield cancel(task);
      // resets reducers
      yield put(authError({ code: "reset" }));
      // runs logout logic
      yield call(logoutSaga);
    }
  }
}

function* authorizeSaga(action) {
  const { email, password, flag, provider } = action.payload;
  if (flag === "signup") {
    try {
      // set loader
      yield put(entranceLoading(true));
      // create a session
      yield call(setPersistence, null);
      // sign up the user
      const userAuthObject = yield call(signUpUser, { email, password });
      analytics_Auth(userAuthObject);
      // save users auth object
      yield put(saveUserAuthObject(userAuthObject));
      // call gather details saga
      yield call(gatherDetailsSaga, { userAuthObject });
    } catch (error) {
      yield put(authError(error));
    }
  } else if (flag === "signin") {
    try {
      // set loader
      yield put(entranceLoading(true));
      // create a session
      yield call(setPersistence, null);
      // sign in the user
      const userAuthObject = yield call(signInUser, { email, password });
      analytics_Auth(userAuthObject);
      // save users auth object
      yield put(saveUserAuthObject(userAuthObject));
      // read user instance from db
      const userInstance = yield call(readUserInstance, { userAuthObject });
      // check if an instance exists
      if (!userInstance.data()) {
        // run gatherDetailsSaga
        yield call(gatherDetailsSaga, { userAuthObject });
      } else {
        // save user instance to redux
        yield put(saveUserDatabaseInstance(userInstance.data()));
        // auth success
        yield put(authSuccess());
        // push user to dashboard
        history.push("/dashboard/overview");
      }
    } catch (error) {
      yield put(authError(error));
    }
  } else if (flag === "social") {
    try {
      // set loader
      yield put(entranceLoading(true));
      // grab socialRedirect flag from redux state
      const redirected = yield select(getSocialRedirectState);
      // If they haven't been redirected yet, then redirect
      if (!redirected) {
        if (provider === "google") {
          // set redirect flag
          yield put(socialRedirect(true));
          // redirect to google
          yield call(googleAuth, null);
        } else {
          // set redirect flag
          yield put(socialRedirect(true));
          // redirect to facebook
          yield call(facebookAuth, null);
        }
      }
      // They've already been redirected so continue logic
      else {
        // create a session
        yield call(setPersistence, null);
        // get redirect result
        const userAuthObject = yield call(getRedirectResult, null);
        analytics_Auth(userAuthObject);
        // provides for the case where user clicks away from social redirect
        if (!userAuthObject) {
          yield put(authError("User not found, please try again"));
        }
        // redirect was successful so continue
        else {
          // save user auth object to state
          yield put(saveUserAuthObject(userAuthObject));
          // check if the user exists
          const userInstance = yield call(readUserInstance, { userAuthObject });
          // check if an instance exists
          if (!userInstance.data()) {
            // If no instance exists run gatherDetailsSaga
            yield call(gatherDetailsSaga, { userAuthObject });
          } else {
            // user exists so save user instance to redux
            yield put(saveUserDatabaseInstance(userInstance.data()));
            // auth success
            yield put(authSuccess());
            // push user to dashboard
            history.push("/dashboard/overview");
          }
        }
      }
    } catch (error) {
      yield put(authError(error));
    }
  }
}

function* gatherDetailsSaga({ userAuthObject }) {
  try {
    // render gather details form
    yield put(gatherDetails(true));
    // disable entrance loader
    yield put(entranceLoading(false));
    // wait for details form to be submitted, when submitted take the payload
    const { payload } = yield take(types.GATHER_DETAILS_COMPLETE);
    // set loader
    yield put(entranceLoading(true));
    // build user instance with payload and auth object
    const { userInstance, uid } = yield call(buildUserInstance, {
      userAuthObject,
      payload
    });
    // write user instance to db
    yield call(writeUserInstance, { userInstance, uid });
    // save user instance to redux
    yield put(saveUserDatabaseInstance(userInstance));
    // push user to dashboard
    history.push("/dashboard/overview");
    // auth success
    yield put(authSuccess());
    //Send new user a verification email
    yield call(sendEmailVerification);
  } catch (error) {
    yield put(authError(error));
  }
}

function* logoutSaga() {
  try {
    // logout user on firebase
    auth.signOut();
    // purge redux store
    persistor.purge();
  } catch (error) {
    yield put(authError(error));
  }
}

export default {
  authAttempt,
  authError,
  authFlow,
  authLogout,
  gatherDetailsComplete,
  gatherDetails,
  entranceLoading,
  //Below exported for offline dev ONLY
  saveUserAuthObject,
  authSuccess
};
