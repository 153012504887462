import React, { useEffect } from "react";
import history from "../../../../utils/routes/history";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import "moment-timezone";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import renderCustomInput from "../../../common/forms/CustomInput";
import renderDatePicker from "../../../common/forms/DatePicker";
import renderErrorMessage from "../../../common/forms/messages/ErrorMessage";

import Person from "@material-ui/icons/PersonOutlined";

const errorSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  dob: Yup.string().required("Required"),
  homePort: Yup.string().required("Required")
});

export default props => {
  const { classes, loading } = props;

  // Only able to catch navigation within the App, once the browser is used then it doesn't concern react-router.
  // No block prompt shown, this seems to act like 'listen' and will only fire props.authLogout if they're not off to the dashboard.
  useEffect(() => {
    const unblock = history.block(location => {
      if (location.pathname !== "/dashboard/overview") {
        props.authLogout();
      }
    });

    return () => {
      unblock();
    };
  });

  //Resets the gatherDetail flag as they try to navigate away with the browser
  //No prompt shown.
  useEffect(() => {
    window.addEventListener("beforeunload", event => {
      props.authLogout();
    });

    return () => {
      window.removeEventListener("beforeunload", event => {
        props.authLogout();
      });
    };
  });

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          dob: null,
          phone: "",
          homePort: ""
        }}
        validationSchema={errorSchema}
        onSubmit={(values, actions) => {
          //Remove whitespace
          values.firstName = values.firstName.trim();
          values.lastName = values.lastName.trim();
          values.phone = values.phone.trim();
          values.homePort = values.homePort.trim();
          values.timeZone = moment.tz.guess();

          props.gatherDetailsComplete({
            firstName: values.firstName,
            lastName: values.lastName,
            dob: moment(values.dob).format("YYYY-MM-DD"),
            phone: values.phone,
            homePort: values.homePort,
            timeZone: values.timeZone
          });
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit }) => (
          <form className={classes.gatherDetailsForm} onSubmit={handleSubmit}>
            <Field
              component={renderCustomInput}
              name="firstName"
              label="First Name"
              icon={<Person />}
              type="text"
            />
            <div className={classes.errorContainer}>
              <ErrorMessage name="firstName" component={renderErrorMessage} />
            </div>
            <Field
              component={renderCustomInput}
              name="lastName"
              label="Last Name"
              icon={<Person />}
              type="text"
            />
            <div className={classes.errorContainer}>
              <ErrorMessage name="lastName" component={renderErrorMessage} />
            </div>
            <Field
              component={renderDatePicker}
              name="dob"
              label="Date of birth"
            />
            <div className={classes.errorContainer}>
              <ErrorMessage name="dob" component={renderErrorMessage} />
            </div>
            <Field
              component={renderCustomInput}
              name="phone"
              label="Phone number (optional)"
              icon={<Person />}
              type="text"
            />
            <div className={classes.errorContainer}>
              <ErrorMessage name="phone" component={renderErrorMessage} />
            </div>
            <Field
              component={renderCustomInput}
              name="homePort"
              label="Home port"
              icon={<Person />}
              type="text"
            />
            <div className={classes.errorContainer}>
              <ErrorMessage name="homePort" component={renderErrorMessage} />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                type="submit"
              >
                {!loading && "Dive in!"}
              </Button>
              {loading && (
                <CircularProgress size={24} className={classes.circleLoader} />
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h4" component="h2">
          Basic Information
        </Typography>
      </div>
      <div className={classes.formTextContainer}>
        <Typography variant="body1">
          Some basic information to get started.
        </Typography>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};
