import { connect } from "react-redux";
import { entranceOperations } from "./duck/index";
import { snackbarOperations } from "../../common/snackbar/duck/index";
import EntranceComponent from "./EntranceComponent";

const mapStateToProps = state => {
  return {
    entrance: state.entrance,
    auth: state.auth,
    beta: state.beta
  };
};

const matchDispatchToProps = dispatch => {
  const authAttempt = formObject =>
    dispatch(entranceOperations.authAttempt(formObject));

  const authError = error => {
    dispatch(entranceOperations.authError(error));
  };

  const authLogout = () => {
    dispatch(entranceOperations.authLogout());
  };

  const entranceLoading = bool =>
    dispatch(entranceOperations.entranceLoading(bool));

  const gatherDetails = bool =>
    dispatch(entranceOperations.gatherDetails(bool));

  const gatherDetailsComplete = formObject =>
    dispatch(entranceOperations.gatherDetailsComplete(formObject));

  const openSnackbar = data => {
    dispatch(snackbarOperations.openSnackbar(data));
  };

  const toggleBeta = bool => {
    dispatch(entranceOperations.betaScreen(bool));
  };

  return {
    authAttempt,
    authLogout,
    authError,
    gatherDetails,
    gatherDetailsComplete,
    openSnackbar,
    toggleBeta,
    entranceLoading
  };
};

const EntranceContainer = connect(
  mapStateToProps,
  matchDispatchToProps
)(EntranceComponent);

export default EntranceContainer;
