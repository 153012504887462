import types from "./types";

//=============================================
//             SEATIME ACTIONS
//=============================================

//Ship
export const addShip = () => {
  return {
    type: types.SEATIME_ADD_SHIP
  };
};

export const editShip = data => {
  return {
    type: types.SEATIME_EDIT_SHIP,
    payload: data
  };
};

//Entry
export const addEntry = () => {
  return {
    type: types.SEATIME_ADD_ENTRY
  };
};

export const addRotation = () => {
  return {
    type: types.SEATIME_ADD_ROTATION
  };
};

export const editEntry = data => {
  return {
    type: types.SEATIME_EDIT_ENTRY,
    payload: data
  };
};

export const toggleDisplay = bool => {
  return {
    type: types.SEATIME_DISPLAY,
    payload: bool
  };
};

export const deleteDialog = data => {
  return {
    type: types.SEATIME_DELETE_DIALOG,
    payload: data
  };
};
