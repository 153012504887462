import types from "./types";

const initialState = {
  // true means table component view
  display: true,
  // addCertificate modal open/close
  addCertificate: false,
  // editCertificate modal open/close
  editCertificate: false,
  // Data to initialize edit certificate form
  editData: {},
  // deleteDialog open/close
  deleteDialog: false,
  // Data to initialize deleteDialog
  deleteData: {},
  // calendarDialog open/close
  calendarDialog: false,
  // calendar data
  calendarData: {}
};

const certificatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CERTIFICATES_DISPLAY:
      return { ...state, display: action.payload };
    case types.CERTIFICATES_ADD:
      return { ...state, addCertificate: !state.addCertificate };
    case types.CERTIFICATES_EDIT:
      return {
        ...state,
        editCertificate: !state.editCertificate,
        editData: action.payload
      };
    case types.CERTIFICATES_DELETE_DIALOG:
      return {
        ...state,
        deleteDialog: !state.deleteDialog,
        deleteData: action.payload
      };
    case types.CERTIFICATES_CALENDAR_DIALOG:
      return {
        state,
        calendarDialog: !state.calendarDialog,
        calendarData: action.payload
      };
    default:
      return state;
  }
};

export default certificatesReducer;
