import React from "react";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";

import ExternalHeader from "../../common/headers/externalHeader/externalHeaderContainer";
import Footer from "../../common/footers/LandingFooter";
import ShipBanner from "../../../assets/shipbanner.jpg";
import Bulky from "../../../assets/bulky.jpg";

import programmerAnimation from "../../../assets/lotties/programmer.json";
import shipAnimation from "../../../assets/lotties/ship.json";

export default props => {
  return (
    <div className="bg-white overflow-hidden font-serif">
      <Helmet>
        <title>Ascertive | About Us</title>
        <meta name="title" content="Ascertive | About Us" />
        <meta
          name="description"
          content="Designed, led and engineered by a group of seafarers; Ascertive is a product of generational experience designed to make your life as a seafarer easier."
        />
        <meta property="og:url" content="https://ascertive.com/aboutus" />
        <meta property="twitter:url" content="https://ascertive.com/aboutus" />
      </Helmet>
      <main className="pt-6">
        <ExternalHeader location={props.location} />
        <section
          id="hero"
          className="relative pb-40 bg-light-blue-800 sm:pb-96 mt-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12"
        >
          <div className="absolute inset-0 rounded-lg px-4 sm:px-6 lg:px-8">
            <img
              className="w-full h-full object-cover rounded-lg"
              src={ShipBanner}
              alt="Cartoon Port"
            />
          </div>
          <div className="relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-primary sm:text-5xl lg:text-6xl pt-12">
              Made by seafarers, for seafarers.
            </h1>
          </div>
        </section>

        <section id="description">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <p className="mt-4 text-2xl text-gray-500 text-center ">
              Designed, led and engineered by a group of seafarers; Ascertive is
              a product of generational experience designed to make your life as
              a seafarer easier.
            </p>
          </div>
        </section>

        <section id="testimonial" className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div className="relative sm:py-16 lg:py-0">
              <div
                aria-hidden="true"
                className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
              >
                <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                <svg
                  className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                  width="404"
                  height="392"
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="404"
                    height="392"
                    fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                  />
                </svg>
              </div>
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                  <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={Bulky}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-rose-500" />
                  <div
                    style={{ mixBlendMode: "multiply" }}
                    className="absolute inset-0 bg-gradient-to-t from-primary via-primary opacity-90"
                  />
                  <div className="relative px-8">
                    <blockquote className="mt-8">
                      <div className="relative text-lg font-medium text-white md:flex-grow">
                        <svg
                          className="absolute -top-4 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-rose-400"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p class="relative">
                          In the current environment its important to always be
                          ready to move on new opportunities. I use Ascertive to
                          keep track of certification for my continued
                          professional developed (CPD), allowing me to plan
                          ahead with confidence.
                        </p>
                      </div>

                      <footer className="mt-4">
                        <p className="text-base font-semibold text-white">
                          David Payne, Managing Director - Marine Services WA.
                        </p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl text-primary font-extrabold tracking-tight sm:text-4xl">
                  Here to serve you
                </h2>
                <div className="mt-6 text-gray-500 space-y-6">
                  <p className="text-lg">
                    Just as the sea demands excellence so do we. Our team
                    strives to continue to develop Ascertive to meet your needs
                    and specifications. There is no red tape, just members of
                    the seafaring community focused on saving you time and
                    effort.
                  </p>
                </div>
              </div>

              <div className="mt-10">
                <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt className="text-base font-medium text-gray-500">
                      Founded
                    </dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-secondary">
                      2020
                    </dd>
                  </div>

                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt className="text-base font-medium text-gray-500">
                      Employees
                    </dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-secondary">
                      7
                    </dd>
                  </div>

                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt className="text-base font-medium text-gray-500">
                      Generations at sea
                    </dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-secondary">
                      5
                    </dd>
                  </div>

                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt className="text-base font-medium text-gray-500">
                      Salt in our veins?
                    </dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-secondary">
                      Plenty
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white relative text-gray-600 body-font mx-auto">
          <div
            className="hidden lg:block lg:absolute lg:inset-0"
            aria-hidden="true"
          >
            <svg
              className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
              width="640"
              height="784"
              fill="none"
              viewBox="0 0 640 784"
            >
              <defs>
                <pattern
                  id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                  x="118"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              {/* <rect
                y="72"
                width="640"
                height="640"
                className="text-gray-50"
                fill="currentColor"
              /> */}
              <rect
                x="118"
                width="404"
                height="784"
                fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
              />
            </svg>
          </div>
          <div className="mt-8 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-8 pt-6 pb-12 sm:pb-12 lg:pb-12">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div>
                  <h2 className="text-3xl mb-16 text-primary font-extrabold text-center tracking-tight sm:text-4xl">
                    The Humans Behind The Screen
                  </h2>
                </div>
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div>
                    <p className="mt-3 mb-8 max-w-3xl text-lg text-gray-500">
                      The team behind Ascertive has a variety of experience that
                      shapes the platform. Some members of the team have spent
                      40 years in the industry sailing across the globe; while
                      others only ten, venturing no further than their domestic
                      waters. Some members of the team have never set foot on a
                      boat bigger than 20 meters but find coding behind a
                      computer suits them better.
                    </p>
                  </div>
                  <div className="mb-8">
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: programmerAnimation,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice"
                        }
                      }}
                      height={250}
                      width={250}
                    />
                  </div>
                </div>
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="mt-3 mb-8 max-w-3xl text-lg text-gray-500">
                    Ascertive started out personal projecct. Our founders' goal
                    was straightforward: solve their own problems with an easy
                    way to manage their certifications so they could focus on
                    their real job. A year later, their two person project has
                    grown some legs and is gaining thrust fast. We continue to
                    grow and learn each day. We'd love to have you along for the
                    ride.
                  </div>
                  <div className="mb-8">
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: shipAnimation,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice"
                        }
                      }}
                      height={250}
                      width={250}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};
