import React, { useEffect } from "react";
import history from "../../../../utils/routes/history";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

export default props => {
  const { classes } = props;

  useEffect(() => {
    // run logout logic
    props.authLogout();
  });

  return (
    <div className={classes.formContainer}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h4" component="h2">
          Sign out
        </Typography>
      </div>
      <div className={classes.formTextContainer}>
        <Typography variant="body1">
          Thank you for using Ascertive, see you again soon!
        </Typography>
      </div>
      <div className={classes.signOutButtonContainer}>
        <Button
          className={classes.signOutButton}
          variant="outlined"
          onClick={() => history.push("/")}
        >
          Home
        </Button>
        <Button
          className={classes.signOutButton}
          variant="outlined"
          onClick={() => history.push("/signin")}
        >
          Sign in
        </Button>
      </div>
    </div>
  );
};
