import { connect } from "react-redux";
import LandingComponent from "./LandingComponent";

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user
  };
};

const LandingContainer = connect(
  mapStateToProps,
  null
)(LandingComponent);

export default LandingContainer;
