//=============================================
//             CERTIFICATE UI TYPES
//=============================================
const CERTIFICATES_DISPLAY = "certificate_display";
const CERTIFICATES_ADD = "certificate_add";
const CERTIFICATES_EDIT = "certificate_edit";
const CERTIFICATES_DELETE_DIALOG = "certificate_delete_dialog";
const CERTIFICATES_CALENDAR_DIALOG = "certificate_calendar_dialog";

export default {
  CERTIFICATES_DISPLAY,
  CERTIFICATES_ADD,
  CERTIFICATES_EDIT,
  CERTIFICATES_DELETE_DIALOG,
  CERTIFICATES_CALENDAR_DIALOG
};
