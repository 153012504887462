import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import renderCustomInput from "../../../common/forms/CustomInput";
import renderErrorMessage from "../../../common/forms/messages/ErrorMessage";

import Facebook from "../../../../assets/facebook.svg";
import Google from "../../../../assets/google.svg";

import Person from "@material-ui/icons/PersonOutlined";
import Lock from "@material-ui/icons/LockOutlined";
import Show from "@material-ui/icons/RemoveRedEye";
import Hide from "@material-ui/icons/VisibilityOff";

const errorSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Required"),
  password: Yup.string()
    .min(6, "Must be more than 6 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .min(6, "Must be more than 6 characters")
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
});

export default props => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const socialRedirect = props.auth.socialRedirect;
  const { classes, authAttempt, loading, setSubmitFunc, recaptcha } = props;

  //AUTH: When user comes back from a redirect, will continue the auth logic
  useEffect(() => {
    if (socialRedirect) {
      authAttempt({ flag: "social" });
    }
    // React Hook useEffect has missing dependencies: 'authAttempt' and 'socialRedirect'. Either include them or remove the dependency array
    // eslint-disable-next-line
  }, []);

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: ""
        }}
        validationSchema={errorSchema}
        onSubmit={(values, actions) => {
          //Remove whitespace
          values.email = values.email.trim();
          props.authAttempt({
            email: values.email,
            password: values.password,
            flag: "signup"
          });
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, validateForm, isValid }) => (
          <form className={classes.signUpForm}>
            <Field
              component={renderCustomInput}
              name="email"
              label="Email"
              icon={<Person />}
              type="email"
            />
            <div className={classes.errorContainer}>
              <ErrorMessage name="email" component={renderErrorMessage} />
            </div>
            <Field
              component={renderCustomInput}
              name="password"
              label="Password"
              icon={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={() => setPasswordShow(!passwordShow)}>
                    {passwordShow ? <Show /> : <Hide />}
                  </IconButton>
                  <Lock />
                </span>
              }
              type={passwordShow ? "text " : "password"}
            />
            <div className={classes.errorContainer}>
              <ErrorMessage name="password" component={renderErrorMessage} />
            </div>
            <Field
              component={renderCustomInput}
              name="confirmPassword"
              label="Confirm Password"
              icon={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  >
                    {confirmPasswordShow ? <Show /> : <Hide />}
                  </IconButton>
                  <Lock />
                </span>
              }
              type={confirmPasswordShow ? "text " : "password"}
            />
            <div className={classes.errorContainer}>
              <ErrorMessage
                name="confirmPassword"
                component={renderErrorMessage}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                onClick={() => {
                  if (!loading) {
                    validateForm().then(() => {
                      if (isValid) {
                        setSubmitFunc({ submit: handleSubmit });
                        props.entranceLoading(true);
                        recaptcha.current.execute();
                      }
                    });
                  }
                }}
              >
                {!loading && "Get Started"}
              </Button>
              {loading && (
                <CircularProgress size={24} className={classes.circleLoader} />
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div className={classes.formContainer}>
      <Helmet>
        <title>Ascertive | Sign Up</title>
        <meta name="title" content="Ascertive | Sign Up" />
        <meta
          name="description"
          content="Create an Ascertive account for free. Sign up via email, Facebook or Google."
        />
        <meta property="og:url" content="https://ascertive.com/signup" />
        <meta property="twitter:url" content="https://ascertive.com/signup" />
      </Helmet>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h4" component="h2">
          Sign up
        </Typography>
      </div>
      <div className={classes.socialContainer}>
        <Button
          className={`${classes.socialButton} ${classes.facebookButton}`}
          variant="outlined"
          type="submit"
          disabled={socialRedirect || loading}
          onClick={() =>
            props.authAttempt({
              flag: "social",
              provider: "facebook"
            })
          }
        >
          <ReactSVG
            alt="Facebook Logo"
            className={`${classes.socialIcon} ${classes.facebookIcon}`}
            src={Facebook}
          />
          <div>Sign up with Facebook</div>
        </Button>
        <Button
          className={`${classes.socialButton} ${classes.googleButton}`}
          variant="outlined"
          type="submit"
          disabled={socialRedirect || loading}
          onClick={() =>
            props.authAttempt({
              flag: "social",
              provider: "google"
            })
          }
        >
          <ReactSVG
            alt="Google Logo"
            className={`${classes.socialIcon} ${classes.googleIcon}`}
            src={Google}
          />
          <div>Sign up with Google</div>
        </Button>
      </div>
      <div className={classes.formTextContainer}>
        <Typography variant="body1">
          or sign up with email and password
        </Typography>
      </div>
      <div>{renderForm()}</div>
      <div className={classes.linksContainer}>
        <Link className={classes.link} to="/signin">
          <Typography variant="body2">Already have an account?</Typography>
        </Link>
      </div>
    </div>
  );
};
