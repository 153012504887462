import React from "react";

// Material UI Core
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

export default ({
  form, //Helps to pass error through
  field, //Passes all props from Formik Field Component
  label, //Passes labelText
  multiline, // Multiline Boolean
  placeholder, // placeholder text
  rows, // specifies number of rows for multiline input
  variant, // Allows different variants of textField
  icon, //Passes endAdornment icon where required
  text, //Passes endAdornment text if required
  button, // Passes endAdornment button if required
  type, //Specifies type of input if required
  disabled, // Allows disabling
  inputProps, //Allows extra props like inputMode to be passed
  className, // classes for the TextInput root
  ...props
}) => (
  <TextField
    className={className}
    multiline={multiline}
    rows={rows}
    error={form.errors[field.name] && form.touched[field.name]}
    label={label}
    placeholder={placeholder}
    type={type}
    disabled={disabled}
    variant={variant ? variant : "outlined"}
    fullWidth
    inputProps={inputProps && inputProps}
    // eslint-disable-next-line
    InputProps={{
      ...field,
      ...props,
      endAdornment: (
        <InputAdornment position="end">
          {text}
          &nbsp;
          {icon}
          {button}
        </InputAdornment>
      )
    }}
  />
);
