import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  container: {
    display: "flex",
    overflow: "hidden"
  },
  icon: {
    marginRight: "0.2rem",
    fontSize: "1.5em"
  }
});
