import { connect } from "react-redux";

import ExternalHeaderComponent from "./ExternalHeader";

const mapStateToProps = state => {
  return {
    user: state.user,
    auth: state.auth
  };
};
const ExternalHeaderContainer = connect(
  mapStateToProps,
  null
)(ExternalHeaderComponent);

export default ExternalHeaderContainer;
