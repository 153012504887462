import types from "./types";

const initialState = {
  loading: false,
  gatherDetails: false,
};

const betaState = {
  betaScreen: true
}

const entranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ENTRANCE_LOADING:
      return { ...state, loading: action.payload };
    case types.GATHER_DETAILS:
      return { ...state, gatherDetails: action.payload };
    case types.AUTH_SUCCESS:
      return { ...state, loading: false, gatherDetails: false };

    case types.AUTH_ERROR:
      return initialState;
    default:
      return state;
  }
};

export const betaReducer = (state = betaState, action) => {
  switch (action.type) {
    case types.BETA_SCREEN:
      return { ...state, betaScreen: action.payload };
    default:
      return state
  }
}

export default entranceReducer


