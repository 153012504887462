//=======================================
//             AUTH TYPES
//=======================================
const SAVE_USER_AUTH_OBJECT = "save_user_auth_object";
const SOCIAL_REDIRECT = "social_redirect";
const AUTH_ATTEMPT = "auth_attempt";
const AUTH_ERROR = "auth_error";
const AUTH_SUCCESS = "auth_success";
const AUTH_LOGOUT = "auth_logout";

//=======================================
//             USER TYPES
//=======================================
const SAVE_USER_DATABASE_INSTANCE = "save_user_database_instance";

//=======================================
//             ENTRANCE UI TYPES
//=======================================

const ENTRANCE_LOADING = "entrance_loading";
const GATHER_DETAILS = "gather_details ";
const GATHER_DETAILS_COMPLETE = "gather_details_complete";
const BETA_SCREEN = "beta_screen";

export default {
  SAVE_USER_AUTH_OBJECT,
  SAVE_USER_DATABASE_INSTANCE,
  SOCIAL_REDIRECT,
  AUTH_ATTEMPT,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  ENTRANCE_LOADING,
  GATHER_DETAILS,
  GATHER_DETAILS_COMPLETE,
  BETA_SCREEN
};
