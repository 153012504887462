import React from "react";
import SpinningLoader from "../SpinningLoader";
import LogoBlue from "../../../../assets/logo/Ascertive_logo+text_640x6x0.png";
import useStyles from "./PageLoader.style";

export default props => {
  const classes = useStyles();
  return (
    <div className={classes.loadingContainer}>
      <SpinningLoader />
      <img src={LogoBlue} className={classes.logo} alt="Ascertive Logo" />
    </div>
  );
};
