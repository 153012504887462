import {
  addShip,
  editShip,
  addEntry,
  editEntry,
  addRotation,
  toggleDisplay,
  deleteDialog
} from "./actions";

export default {
  addShip,
  editShip,
  addEntry,
  editEntry,
  addRotation,
  toggleDisplay,
  deleteDialog
};
