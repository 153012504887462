import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  initialSection: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    padding: "8rem 0"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  initialHeader: {
    color: "white",
    marginBottom: "1.5rem",
    fontWeight: "800",
    maxWidth: "750px",
    textAlign: "center"
  },
  text: {
    marginBottom: "2rem",
    maxWidth: "750px",
    textAlign: "center",
    color: "white"
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    "@media (max-width: 700px)": {
      padding: "2rem"
    }
  },
  errorMessageContainer: {
    height: "2.5rem",
    marginTop: "0.4rem",
    "@media (min-width: 370)": {
      marginBottom: "-2.9rem"
    }
  },
  form: {
    width: "100%",
    maxWidth: "750px"
  },
  buttonWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    display: "block",
    padding: "0.75rem 1.25rem",
    backgroundColor: theme.palette.success.main,
    color: "white",
    borderRadius: "5px",
    fontWeight: 700
  },
  buttonSuccess: {
    display: "block",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen"
    }
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));
