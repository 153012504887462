import types from "./types";

//=============================================
//             SNACKBAR ACTIONS
//=============================================

export const openSnackbar = data => {
  return {
    type: types.SNACKBAR_OPEN,
    payload: data
  };
};

export const closeSnackbar = () => {
  return {
    type: types.SNACKBAR_CLOSE
  };
};
