import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import history from "../../../utils/routes/history";

//MUI
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

//Components
import ExternalHeader from "../../common/headers/externalHeader/externalHeaderContainer";
import Footer from "../../common/footers/LandingFooter";
import Privacy from "./content/privacy";

//Styles
import useStyles from "./Info.style";

export default props => {
  const [value, setValue] = useState(props.match.path);
  const classes = useStyles();

  const handleChange = (_event, newValue) => {
    history.push(newValue);
  };

  console.log(props);

  return (
    <Fragment>
      <Helmet>
        <title>Ascertive | Terms and Policies</title>
        <meta name="title" content="Ascertive | Terms and Policies" />
        <meta
          name="description"
          content="Thanks for using Ascertive! Our mission is to create a more efficient way of storing, sharing and monitoring your certification and progress as a seafarer, by providing a platform to keep your content safe and accessible."
        />
        <meta
          property="og:url"
          content="https://ascertive.com/information/terms"
        />
        <meta
          property="twitter:url"
          content="https://ascertive.com/information/terms"
        />
      </Helmet>
      <div className="pt-6">
        <ExternalHeader location={props.location} />
      </div>
      <div className={classes.pageContainer}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Terms of Service" value="/terms" />
          <Tab label="Privacy Policy" value="/privacy" />
          <Tab label="Acceptable Use" value="/acceptable_use" />
        </Tabs>
        <Privacy classes={classes} />
      </div>
      <Footer />
    </Fragment>
  );
};
