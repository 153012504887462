import React from "react";

//MUI
import Typography from "@material-ui/core/Typography";

export default props => {
  return (
    <Typography className={props.classes.textContainer} variant="body1">
      <p>
        <strong>Ascertive Privacy Policy</strong>
      </p>
      <p>
        Created: November 9th 2019
        <br />
        Effective: November 9th 2019
      </p>
      <p>
        Thanks for using Ascertive! Here we describe how we collect, use, and
        handle your personal data when you use our website, application and
        services ("<strong>Services</strong>").
      </p>
      <p>
        <strong>What we collect and Why</strong>
      </p>
      <p>
        We collect and use the following information to provide, improve,
        protect and promote our services:
      </p>
      <p>
        <em>Account Information. </em>We collect and associate with your
        account, the information you provide to us when you do things such as
        sign up for your account; like your name, email address, phone number
        and home port (repatriation city or country).
      </p>
      <p>
        <em>Your Stuff. </em>Our Services are designed as a simple and
        personalized way for you to store your certification, documents, files,
        records, and so on ("<strong>Your</strong>
        <strong> Stuff</strong>"). Giving you the ability to share Your Stuff
        and access it from multiple devices. To make that possible, we store,
        process and transmit Your Stuff as well as information related to it.
        This related information includes your profile information that makes it
        easier to share Your Stuff with others at your request, as well as
        things like the size of the file, name and content.
      </p>
      <p>
        <em>Usage Infomation. </em>We collect information related to how you use
        the Services, including actions you take in your account (like sharing,
        creating, uploading and editing certificates, logs or files). We use
        this information to provide, improve, and promote our Services, and
        protect Ascertive users.
      </p>
      <p>
        <em>Device information.</em> We also collect information from and about
        the devices you use to access the Services. This includes things like IP
        addresses, the type of browser and device you use, the web page you
        visited before coming to our sites, and identifiers associated with your
        devices. Your devices (depending on their settings) may also transmit
        location information to the Services. For example, we use device
        information to detect abuse and identify and troubleshoot bugs.
      </p>
      <p>
        <em>Cookies and other technologies.</em> We use technologies like
        cookies to provide, improve, protect, and promote our Services. For
        example, cookies help us with things like remembering your username for
        your next visit, understanding how you are interacting with our
        Services, and improving them based on that information. You can set your
        browser to not accept cookies, but this may limit your ability to use
        the Services. We may also use third-party service providers that set
        cookies and similar technologies to promote Ascertive services.&nbsp;
      </p>
      <p>
        <em>Marketing.</em> We give users the option to use our Services free of
        charge. These free Services are made possible by the fact that some
        users upgrade to one of our paid Services. If you register for our
        Services, we will, from time to time, send you information about
        upgrades when permissible. Users who receive these marketing materials
        can opt out at any time. If you don&rsquo;t want to receive a particular
        type of marketing material from us, click the &lsquo;unsubscribe&rsquo;
        link in the corresponding emails, or update your preferences in the
        Notifications section of your personal account.
      </p>
      <p>
        <em>Bases for processing your data.</em> We collect and use the personal
        data described above in order to provide you with the Services in a
        reliable and secure manner. We also collect and use personal data for
        our legitimate business needs.
      </p>
      <p>
        <strong>With Whom</strong>
      </p>
      <p>
        We may share information as discussed below, but we won&rsquo;t sell it
        to advertisers or other third parties.
      </p>
      <p>
        <em>Other users.</em> Our Services display information like your name,
        profile picture, email address, phone number, and Your Stuff to other
        users you choose to share with.&nbsp;
      </p>
      <p>
        <em>Law &amp; Order and the Public Interest.</em> We may disclose your
        information to third parties if we determine that such disclosure is
        reasonably necessary to: (a) comply with any applicable law, regulation,
        legal process, or appropriate government request; (b) protect any person
        from death or serious bodily injury; (c) prevent fraud or abuse of
        Ascertive or our users; (d) protect Acertive&rsquo;s rights, property,
        safety, or interest; or (e) perform a task carried out in the public
        interest.
      </p>
      <p>
        Stewardship of your data is critical to us and a responsibility that we
        embrace.&nbsp;
      </p>
      <p>
        <strong>How</strong>
      </p>
      <p>
        <em>Security.</em> We have a team dedicated to keeping your information
        secure and testing for vulnerabilities. We continue to work on features
        to keep your information safe in addition to things like encryption of
        files at rest, and alerts when new devices and apps are linked to your
        account. We deploy automated technologies to detect abusive behavior and
        content that may harm our Services, you, or other users.
      </p>
      <p>
        <em>User Controls.</em> You can access, amend, download, and delete your
        personal information by logging into your Ascertive account and going to
        your account settings page.&nbsp;
      </p>
      <p>
        <em>Retention.</em> When you sign up for an account with us, we&rsquo;ll
        retain information you store on our Services for as long as your account
        exists or as long as we need it to provide you the Services. If you
        delete your account, we&rsquo;ll initiate deletion of this information
        after 30 days. But please note: (1) there might be some latency in
        deleting this information from our servers and back-up storage; and (2)
        we may retain this information if necessary to comply with our legal
        obligations, resolve disputes, or enforce our agreements.
      </p>
      <p>
        <strong>Where</strong>
      </p>
      <p>
        <em>Around the world.</em> To provide you with the Services, we may
        store, process, and transmit data in the United States and locations
        around the world&mdash;including those outside your country. Data may
        also be stored locally on the devices you use to access the Services.
      </p>
      <p>
        <strong>Your Control and Access of Your Data</strong>
      </p>
      <p>
        Providing some information is optional, if you choose not to enter
        certain personal information we will be unable to provide you with an
        account and subsequently access to our Services. You have control over
        your personal data and how it&rsquo;s collected, used, and shared.
      </p>
      <p>For example, you can:</p>
      <ul>
        <li>
          <em>Delete Your Stuff in your Acsertive account.</em>&nbsp;
        </li>
        <li>
          <em>Change or correct personal data.</em> You can manage your account
          and the content contained in it, as well as edit some of your personal
          data, through your account settings page.
        </li>
        <li>
          <em>Object to the processing of your personal data.</em> Depending on
          the processing activity, you can request that we stop or limit
          processing of your personal data. If you would like to submit an
          objection, please email us at{" "}
          <a href="mailto:privacy@ascertive.com">privacy@ascertive.com</a>.
        </li>
        <li>
          <em>Ask for a copy of your information. </em>You have the right to ask
          for a copy of any personal information we hold about you, and to ask
          for it to be corrected if you think it is wrong. If you&rsquo;d like
          to ask for a copy of your information, or to have it corrected, please
          contact us at{" "}
          <a href="mailto:privacy@ascertive.com">privacy@ascertive.com</a>.
        </li>
      </ul>
      <p>
        <strong>Changes</strong>
      </p>
      <p>
        We may revise this Privacy Policy from time to time, and will post the
        most current version on our website. If a revision meaningfully reduces
        your rights, we will notify you.
      </p>
      <p>
        <strong>Contact</strong>
      </p>
      <p>
        Have questions or concerns about Ascertive, our Services, and privacy?
        Contact our Data Protection Officer at{" "}
        <a href="mailto:privacy@ascertive.com">privacy@ascertive.com</a>. If
        they can&rsquo;t answer your question, you have the right to contact
        your local data protection supervisory authority.
      </p>
    </Typography>
  );
};
