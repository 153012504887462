//Import Layouts
import Landing from "../../app/pages/landing/LandingContainer";
import Entrance from "../../app/pages/entrance/EntranceContainer";
import NoMatch from "../../app/pages/nomatch/NomatchComponent";
import Unsubscribe from "../../app/pages/unsubscribe/UnsubComponent";
import AboutUs from "../../app/pages/aboutus/AboutusComponent";
import ContactUs from "../../app/pages/contactus/ContactusComponent";
import Terms from "../../app/pages/information/TermsComponent";
import Privacy from "../../app/pages/information/PrivacyComponent";
import AcceptableUse from "../../app/pages/information/AcceptableUseComponent";

//OFFLINE DEV
// import DashboardContainerDEV from "../../dev/dashboard/DashboardContainerDEV";

//Code Split the Dashboard & Profile
import AsyncComponent from "./AsyncComponent";
const AsyncDashboard = AsyncComponent(() =>
  import("../../app/pages/dashboard/DashboardContainer")
);
const AsyncProfile = AsyncComponent(() =>
  import("../../app/pages/profile/ProfileComponent")
);

const indexRoutes = [
  {
    exact: true,
    path: "/",
    component: Landing
  },
  {
    path: "/aboutus",
    component: AboutUs
  },
  {
    path: "/contactus",
    component: ContactUs
  },
  {
    path: "/dashboard",
    component:
      // process.env.NODE_ENV === "development"
      //   ? DashboardContainerDEV
      //   :
      AsyncDashboard
  },
  {
    path: "/:direction(signin|signup|signout|resetpassword)",
    component: Entrance
  },
  {
    path: "/profile/:id",
    component: AsyncProfile
  },
  {
    path: "/terms",
    component: Terms
  },
  {
    path: "/acceptable_use",
    component: AcceptableUse
  },
  {
    path: "/privacy",
    component: Privacy
  },
  {
    path: "/unsubscribe",
    component: Unsubscribe
  },
  {
    component: NoMatch
  }
];

export default indexRoutes;
