import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  button: {
    borderRadius: "10px",
    padding: "2px 12px",
    color: "white",
    fontWeight: 700,
    "&:focus": {
      outline: "none"
    }
  },
  blue: {
    backgroundColor: theme.palette.secondary.main
  },
  green: {
    backgroundColor: theme.palette.success.main
  },
  red: {
    backgroundColor: theme.palette.error.main
  }
}));

export default ({
  text, // button text
  color, // button color (blue or green)
  buttonHandler, // function on click
  stopPropagation, // boolean on whether you want event to propogate up to parent.
  style,
  type,
  ...props
}) => {
  const classes = styles();
  const determineClass = () => {
    if (color === "blue") {
      return classes.blue;
    } else if (color === "green") {
      return classes.green;
    } else if (color === "red") {
      return classes.red;
    } else {
      return classes.blue;
    }
  };
  return (
    <Button
      type={type}
      className={` ${props.className} ${classes.button} ${determineClass()}`}
      variant="contained"
      onClick={e => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        buttonHandler();
      }}
      style={style}
    >
      {props.children}
    </Button>
  );
};
