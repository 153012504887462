import types from "./types";

//=============================================
//             CERTIFICATE UI ACTIONS
//=============================================

export const toggleDisplay = bool => {
  return {
    type: types.CERTIFICATES_DISPLAY,
    payload: bool
  };
};

export const addCertificate = () => {
  return {
    type: types.CERTIFICATES_ADD
  };
};

export const editCertificate = data => {
  return {
    type: types.CERTIFICATES_EDIT,
    payload: data
  };
};

export const deleteDialog = data => {
  return {
    type: types.CERTIFICATES_DELETE_DIALOG,
    payload: data
  };
};

export const calendarDialog = data => {
  return {
    type: types.CERTIFICATES_CALENDAR_DIALOG,
    payload: data
  };
};
