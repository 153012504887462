import types from "../pages/entrance/duck/types";

const initialState = {
  instance: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_USER_DATABASE_INSTANCE:
      return { ...state, instance: action.payload };
    case types.AUTH_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
