const snackbarStyle = () => ({
  success: {
    backgroundColor: "#43a047"
  },
  error: {
    backgroundColor: "#d32f30"
  },
  info: {
    backgroundColor: "#1a75d2"
  },
  warning: {
    backgroundColor: "#ffa002"
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: "1rem"
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

export default snackbarStyle;
