import { connect } from "react-redux";
import { snackbarOperations } from "./duck/index";

import withStyles from "@material-ui/core/styles/withStyles";
import snackbarStyle from "./Snackbar.style";
import SnackbarComponent from "./SnackbarComponent";

const mapStateToProps = state => {
  return {
    snackbar: state.snackbar
  };
};

const mapDispatchToProps = dispatch => {
  const openSnackbar = data => {
    dispatch(snackbarOperations.openSnackbar(data));
  };
  const closeSnackbar = () => {
    dispatch(snackbarOperations.closeSnackbar());
  };

  return {
    openSnackbar,
    closeSnackbar
  };
};

const SnackbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarComponent);

export default withStyles(snackbarStyle)(SnackbarContainer);
